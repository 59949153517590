import styled from 'styled-components';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { H1 } from '../atoms/h1';
import { useCreateJobMutation, useAreasQuery, GQLAreaByNameQuery, GQLAreaByNameQueryVariables, useJobCategoriesQuery } from '../../generated/graphql';
import { Prefecture, prefectures, PrefectureSelect, isPrefectureName } from '../atoms/prefecture-select';
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { GoogleMap, useLoadScript, InfoWindow, StandaloneSearchBox, Autocomplete, Marker } from '@react-google-maps/api';
import { useApolloClient } from '@apollo/react-hooks';
import { AREA_BY_NAME_QUERY } from '../../documents/area'
import { AREA_FRAGMENT } from '../../documents/fragments'
import { isNullish } from '../../utils/type-check';
import gql from 'graphql-tag';
import { CheckboxList } from '../molecules/checkbox-list';
import { LoadingIndicator } from '../atoms/loading-indicator';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
/// <reference path="<relevant path>/node_modules/@types/googlemaps/index.d.ts" />

const NewJobPageOuter = styled.div`
  
`

type Geometry = {
  lat: number,
  lng: number
}

function resolveNumber(value: number | (() => number)): number{
  if(typeof value === 'function'){
    return value()
  } else {
    return value
  }
}

export const AddJobPage: FunctionComponent = () => {
  const [name, setName] = useState('Test job')
  const [fee, setFee] = useState(1000000)
  const [deadline, setDeadline] = useState<Date | null>(new Date())
  const [description, setDescription] = useState('Test description')
  const [address, setAddress] = useState('東京都千代田区千代田1-1')
  const [prefecture, setPrefecture] = useState<Prefecture>("東京都")
  const [areaCode, setAreaCode] = useState<string | null>(null)
  const [location, setLocation] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({ lat: 35.6812362, lng: 139.7671248 })
  const [mapLocation, setMapLocation] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({ lat: 35.6812362, lng: 139.7671248 })
  const jobCategoriesQuery = useJobCategoriesQuery()
  const client = useApolloClient()

  const areasQuery = useAreasQuery({variables: {
    prefecture
  }})
  const [createJob] = useCreateJobMutation()
  const history = useHistory()
  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete | null>(null)
  const [map, setMap] = useState<google.maps.Map>()
  const [categoryNames, setCategoryNames] = useState<string[]>([])
  
  useEffect(() => {
    if(!areasQuery.loading && areaCode === null || !areasQuery.data?.areas.find(area => area.code === areaCode)){
      setAreaCode(areasQuery.data?.areas[0].code ?? null)
    }
  }, [prefecture, areasQuery.loading])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => console.log(position), err => console.log(err))
  }, [])


  if(jobCategoriesQuery.loading) return <LoadingIndicator />

  console.log(prefecture, areaCode, address)
  return <NewJobPageOuter>
    <H1>New job</H1>
    name: <input type="text" value={name} onChange={e => setName(e.currentTarget.value)} /><br />
    fee: <input type="number" value={fee} onChange={e => setFee(Number(e.currentTarget.value))} /><br />
    deadline: <ReactDatePicker selected={deadline} onChange={date => setDeadline(Array.isArray(date) ? date[0] : date)} /><br />
    description: <textarea value={description} onChange={e => setDescription(e.currentTarget.value)} /><br />
    address: <input type="text" value={address} onChange={e => setAddress(e.currentTarget.value)} /><br />
    prefecture: <PrefectureSelect value={prefecture} onChange={name => setPrefecture(name)} /><br />
    area: <select value={areaCode ?? undefined} onChange={e => setAreaCode(e.currentTarget.value)}>
      {areasQuery.data?.areas.map(area => <option key={area.code} value={area.code}>{area.city}</option>)}
    </select><br />
    <CheckboxList
      entries={jobCategoriesQuery.data?.jobCategories.map(({name, screenName}) => ({label: screenName, value: name})) ?? []}
      checked={categoryNames}
      onChange={checked => {
        setCategoryNames(checked)
      }}
    />
    <GoogleMap
      mapContainerStyle={{width: 800, height: 800}}
      zoom={15}
      onCenterChanged={() => {
        const center = map?.getCenter()
        console.log(center)
        if(center){
          setMapLocation(center)
        }
      }}
      center={mapLocation}
      onClick={async e => {
        const geocorder = new google.maps.Geocoder()
        setLocation(e.latLng)
        console.log(e)
        geocorder.geocode({
          location: {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          }
        }, async (res, status) => {
          const {prefecture, city, address} = formatGeocorderResults(res)
          if(isPrefectureName(prefecture) && !isNullish(city)){
            const areaQueryResult = await client.query<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>({
              query: gql `${AREA_BY_NAME_QUERY}${AREA_FRAGMENT}`,
              variables: {
                prefecture,
                city
              }
            })
            const area = areaQueryResult.data.area
            if(area){
              setPrefecture(prefecture)
              setAreaCode(area.code)
              setAddress(address)
            }
          }
        })
      }}
      onLoad={e => setMap(e)}
    >
      <Autocomplete
        onLoad={(autoComplete) => setAutoComplete(autoComplete)}
        onPlaceChanged={() => {
          const location = autoComplete?.getPlace().geometry?.location
          setLocation(location ?? { lat: 35.6812362, lng: 139.7671248 })
          setMapLocation(location ?? { lat: 35.6812362, lng: 139.7671248 })
        }}
      >
        <input
          type="text"
          placeholder="Customized your placeholder"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginLeft: "-120px"
          }}
        />
      </Autocomplete>
      <Marker
        position={location}
        draggable={true}
      />
    </GoogleMap>
    <button onClick={async () => {
      if(deadline && areaCode){
        const createJobResult = await createJob({variables: {
          job: {
            name,
            fee,
            deadline: deadline.toISOString(),
            description,
            address,
            areaCode,
            categoryNames,
            lat: resolveNumber(location.lat),
            lng: resolveNumber(location.lng)
          }
        }})
        console.log(createJobResult.data)
        history.replace(`/jobs/${createJobResult.data?.createJob.id}`)
      }
    }}>Create Job</button>
  </NewJobPageOuter>
}
const formatGeocorderResults = (res: google.maps.GeocoderResult[]): {
  prefecture: string | null,
  city: string | null,
  address: string
} => {
  const geocode = res.find(geocorderResult => !['establishment', 'route', 'postal_code'].some(type => geocorderResult.types.includes(type)))
  const addressComponents = res.find(geocorderResult => ['locality', 'political'].every(type => geocorderResult.types.includes(type)))?.address_components
  const prefecture = addressComponents?.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))?.long_name ?? null
  const city = addressComponents?.find(addressComponent => addressComponent.types.includes('locality'))?.long_name ?? null
  return {
    prefecture,
    city,
    address: geocode ? geocode.formatted_address.replace(/(?:日本、)?(?:〒\d{3}-\d{4}\s?)?/, '') : ''
  }
}