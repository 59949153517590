import styled from 'styled-components';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '../atoms/image';

const Outer = styled.div`
margin-bottom: 16rem;
`

const Header = styled.div`
  display: flex;
  max-width: 50rem;
  align-items: flex-end;
  margin: 0 auto 4rem;
`

const TitleContainer = styled.div`
  flex: 0 0 16rem;
`

const Title = styled.div`    
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
  color: var(--head-text-color);
  letter-spacing: 0.125em;
  font-size: 2rem;
`

const SubTitle = styled.div`
  color: #1e753b;
  font-weight: 700;
  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 8rem;
    background-color: #a8a8a8;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
`

const ListOuter = styled.div`
`

const Description = styled.div`
  flex: 1 1 0;
  line-height: 1.5;
  font-size: 0.9rem;
`

const ArticleListContainer = styled.div`
  position: relative;
  max-width: 60rem;
  margin: 0 auto;
`

const ArticleList = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`

const ArticleListItem = styled.div`
  --gutter-width: 1rem;
  --count: 5;
  flex: 0 0 calc((100% - (var(--gutter-width) * (var(--count) - 1))) / var(--count));
  min-width: 0;
`

const ShowMoreLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  width: 100%;
  &::before {
    content: '';
    width: 100vw;
    height: 10rem;
    margin: 0 calc((100vw - 100%) / -2);
    background-color: #3c3c3c;
    display: block;
  }
`
const ShowMoreLink = styled(Link)`
  font-weight: 700;
  text-decoration: underline;
  color: #f8ea21;
  font-size: 0.9rem;
  background-color: #3c3c3c;
  padding: 0.25rem 2rem 1rem 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
`

type ArticleItem = {
  imageUrl?: string,
  name: string,
  link?: string
}

type TopArticleListContainerProps = {
  title: ReactNode,
  subtitle: ReactNode,
  description: ReactNode,
  items: ArticleItem[],
  moreLink: string
}
export const TopArticleListContainer: FunctionComponent<TopArticleListContainerProps> = ({title, subtitle, description, items, moreLink}) => {
  return <Outer>
    <Header>
      <TitleContainer>
        <Title>
          {title}
        </Title>
        <SubTitle>
          {subtitle}
        </SubTitle>
      </TitleContainer>
      <Description>
        {description}
      </Description>
    </Header>
    <ArticleListContainer>
      <ArticleList>
        {
          items.map((item, i) => {
            return <ArticleListItem key={i}>
              <Article articleItem={item}/>
            </ArticleListItem>
          })
        }
      </ArticleList>
      <ShowMoreLinkContainer>
        <ShowMoreLink to={moreLink}>＋ もっと見る</ShowMoreLink>
      </ShowMoreLinkContainer>
    </ArticleListContainer>
  </Outer>  
}
 
const ArticleOuter = styled(Link)`
  width: 100%;
  display: block;
`
const ImageOuter = styled.div`
  width: 100%;
`
const ArticleLabel = styled.div`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 1rem 1rem 0;
  margin-bottom: -1.9rem;
  color: white;
`

type ArticleItemProps = {
  articleItem: ArticleItem
}
const Article: FunctionComponent<ArticleItemProps> = ({articleItem}) => {
  return <ArticleOuter to={articleItem.link ?? ''}>
    <ImageOuter>
      <Image src={articleItem.imageUrl} style={{backgroundColor: '#eee', borderRadius: '100%'}} width="400" height="400" />
    </ImageOuter>
    <ArticleLabel>
      {articleItem.name}
    </ArticleLabel>
  </ArticleOuter>
}