import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
}

export interface GQLAddJobCategoryInput {
  name: Scalars['String'];
  screenName: Scalars['String'];
}

export interface GQLAddNewsPostInput {
  content: Scalars['String'];
  title: Scalars['String'];
}

export interface GQLAddPhotographicCategoryInput {
  name: Scalars['String'];
  screenName: Scalars['String'];
}

export interface GQLAddStudioImageInput {
  studioId: Scalars['ID'];
}

export interface GQLArea {
  __typename?: 'Area';
  city: Scalars['String'];
  code: Scalars['ID'];
  prefecture: Scalars['String'];
}

/** Business form */
export enum GQLBusinessForm {
  Corporation = 'Corporation',
  Individual = 'Individual'
}

export interface GQLCreateInquiryInput {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  text: Scalars['String'];
}

export interface GQLCreatePaymentRequestInput {
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  bankAccountType: Scalars['String'];
  bankName: Scalars['String'];
  branchName: Scalars['String'];
}

export interface GQLCreateStudioEntryInput {
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  studioAddress: Scalars['String'];
  studioName: Scalars['String'];
  studioZipcode: Scalars['String'];
  text: Scalars['String'];
}

export interface GQLCreateStudioInput {
  address: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  screenName: Scalars['String'];
  zipcode: Scalars['String'];
}


export interface GQLDeleteGalleryImageInput {
  galleryImageId: Scalars['ID'];
}

export interface GQLDeleteInquiryInput {
  inquiryId: Scalars['Int'];
}

export interface GQLDeleteJobCategoryInput {
  jobCategoryId: Scalars['ID'];
}

export interface GQLDeletePaymentRequestInput {
  paymentRequestId: Scalars['ID'];
}

export interface GQLDeletePhotographicCategoryInput {
  photographicCategoryId: Scalars['ID'];
}

export interface GQLDeleteStudioEntryInput {
  studioEntryId: Scalars['ID'];
}

export interface GQLDeleteStudioImageInput {
  studioImageId: Scalars['ID'];
}

export interface GQLDeleteStudioInput {
  studioId: Scalars['ID'];
}

export interface GQLDeliverable {
  __typename?: 'Deliverable';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  job: GQLJob;
  jobId: Scalars['Float'];
  originalFileName: Scalars['String'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user: GQLUser;
  userId: Scalars['Float'];
}

export interface GQLDeliverablePreview {
  __typename?: 'DeliverablePreview';
  createdAt: Scalars['DateTime'];
  height: Scalars['Float'];
  id: Scalars['ID'];
  job: GQLJob;
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user: GQLUser;
  width: Scalars['Float'];
}

export interface GQLDirectMessage {
  __typename?: 'DirectMessage';
  createdAt: Scalars['DateTime'];
  from: GQLUser;
  id: Scalars['ID'];
  text: Scalars['String'];
  to: GQLUser;
  updatedAt: Scalars['DateTime'];
}

export interface GQLEquipment {
  __typename?: 'Equipment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  screenName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<GQLUser>;
}

export interface GQLGalleryImage {
  __typename?: 'GalleryImage';
  createdAt: Scalars['DateTime'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  size: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user: GQLUser;
  userId: Scalars['ID'];
  width: Scalars['Int'];
}

export interface GQLGalleryImagesInput {
  userName: Scalars['String'];
}

export interface GQLInquiriesInput {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}

export interface GQLInquiry {
  __typename?: 'Inquiry';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  text: Scalars['String'];
}

export interface GQLInquiryInput {
  inquiryId: Scalars['ID'];
}

export interface GQLJob {
  __typename?: 'Job';
  address: Scalars['String'];
  applicationCount: Scalars['Int'];
  applied: Scalars['Boolean'];
  area: GQLArea;
  assigned: Scalars['Boolean'];
  categories: Array<GQLJobCategory>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deadline: Scalars['DateTime'];
  deliverablePreviews: Array<GQLDeliverablePreview>;
  deliverables: Array<GQLDeliverable>;
  description: Scalars['String'];
  entries: Array<GQLJobApplication>;
  fee: Scalars['Float'];
  id: Scalars['ID'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  messages: Array<GQLJobMessage>;
  name: Scalars['String'];
  own: Scalars['Boolean'];
  photographer?: Maybe<GQLUser>;
  photographerId?: Maybe<Scalars['ID']>;
  status: GQLJobStatus;
  stripePaymentIntentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: GQLUser;
  userId: Scalars['ID'];
}

export interface GQLJobApplication {
  __typename?: 'JobApplication';
  accepted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  job: GQLJob;
  jobId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: GQLUser;
  userId: Scalars['ID'];
}

export interface GQLJobCategory {
  __typename?: 'JobCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  screenName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface GQLJobComment {
  __typename?: 'JobComment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  job: GQLJob;
  jobId: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: GQLUser;
}

export interface GQLJobCommentInput {
  jobId: Scalars['ID'];
  text: Scalars['String'];
}

export interface GQLJobInput {
  address: Scalars['String'];
  areaCode: Scalars['String'];
  categoryNames: Array<Scalars['String']>;
  deadline: Scalars['DateTime'];
  description: Scalars['String'];
  fee: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name: Scalars['String'];
  photographerId?: Maybe<Scalars['ID']>;
}

export interface GQLJobMessage {
  __typename?: 'JobMessage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  job: GQLJob;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: GQLUser;
}

export interface GQLJobMessageInput {
  jobId: Scalars['ID'];
  text: Scalars['String'];
}

/** Job status */
export enum GQLJobStatus {
  Closed = 'Closed',
  Completed = 'Completed',
  Deliverd = 'Deliverd',
  Open = 'Open',
  Preview = 'Preview',
  PreviewAccepted = 'PreviewAccepted',
  WatingForPaymentConfirm = 'WatingForPaymentConfirm'
}

export interface GQLMutation {
  __typename?: 'Mutation';
  acceptDeliverablePreviews: GQLJob;
  acceptJobApplication: GQLJobApplication;
  addDeliverable: GQLDeliverable;
  addDeliverablePreview: GQLDeliverablePreview;
  addGalleryImage: GQLGalleryImage;
  addJobCategory: GQLJobCategory;
  addJobComment: GQLJobComment;
  addJobMessage: GQLJobMessage;
  addNewsPost: GQLNewsPost;
  addPaymentMethod: GQLPaymentMethod;
  addPhotographerComment: GQLPhotographerComment;
  addPhotographicCategory: GQLPhotographicCategory;
  addStudioImage: GQLStudioImage;
  applyForJob: GQLJobApplication;
  cancelApplyForJob?: Maybe<GQLJobApplication>;
  changePassword: Scalars['Boolean'];
  createInquiry: GQLInquiry;
  createJob: GQLJob;
  createPaymentRequest: GQLPaymentRequest;
  createStudio: GQLStudio;
  createStudioEntry: GQLStudioEntry;
  deleteDeliverable?: Maybe<Array<GQLDeliverable>>;
  deleteGalleryImage: Scalars['ID'];
  deleteInquiry: Scalars['ID'];
  deleteJobCategory: Scalars['ID'];
  deleteNewsPost: Scalars['ID'];
  deletePaymentRequest: Scalars['ID'];
  deletePhotographicCategory: Scalars['ID'];
  deleteStudio: Scalars['ID'];
  deleteStudioEntry: Scalars['ID'];
  deleteStudioImage: Scalars['ID'];
  forceVerifyEmail: GQLUser;
  registerUser: GQLUser;
  requestPasswordReset: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  sendDirectMessage: GQLDirectMessage;
  signin: GQLToken;
  updatePhotographerProfile: GQLUser;
  updateProfile: GQLUser;
  updateProfileImage: GQLProfileImage;
  updateStudio: GQLStudio;
  verifyEmail: Scalars['Boolean'];
}


export interface GQLMutationAcceptDeliverablePreviewsArgs {
  jobId: Scalars['ID'];
}


export interface GQLMutationAcceptJobApplicationArgs {
  jobId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface GQLMutationAddDeliverableArgs {
  data: Scalars['Upload'];
  jobId: Scalars['ID'];
}


export interface GQLMutationAddDeliverablePreviewArgs {
  image: Scalars['Upload'];
  jobId: Scalars['ID'];
}


export interface GQLMutationAddGalleryImageArgs {
  image: Scalars['Upload'];
}


export interface GQLMutationAddJobCategoryArgs {
  input: GQLAddJobCategoryInput;
}


export interface GQLMutationAddJobCommentArgs {
  jobComment: GQLJobCommentInput;
}


export interface GQLMutationAddJobMessageArgs {
  jobMessage: GQLJobMessageInput;
}


export interface GQLMutationAddNewsPostArgs {
  addNewsPostInput: GQLAddNewsPostInput;
}


export interface GQLMutationAddPaymentMethodArgs {
  paymentMethodId: Scalars['String'];
}


export interface GQLMutationAddPhotographerCommentArgs {
  photographerComment: GQLPhotographerCommentInput;
}


export interface GQLMutationAddPhotographicCategoryArgs {
  input: GQLAddPhotographicCategoryInput;
}


export interface GQLMutationAddStudioImageArgs {
  image: Scalars['Upload'];
  input: GQLAddStudioImageInput;
}


export interface GQLMutationApplyForJobArgs {
  jobId: Scalars['ID'];
}


export interface GQLMutationCancelApplyForJobArgs {
  jobId: Scalars['ID'];
}


export interface GQLMutationChangePasswordArgs {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}


export interface GQLMutationCreateInquiryArgs {
  input: GQLCreateInquiryInput;
}


export interface GQLMutationCreateJobArgs {
  job: GQLJobInput;
}


export interface GQLMutationCreatePaymentRequestArgs {
  input: GQLCreatePaymentRequestInput;
}


export interface GQLMutationCreateStudioArgs {
  input: GQLCreateStudioInput;
}


export interface GQLMutationCreateStudioEntryArgs {
  input: GQLCreateStudioEntryInput;
}


export interface GQLMutationDeleteDeliverableArgs {
  deliverableId: Scalars['ID'];
}


export interface GQLMutationDeleteGalleryImageArgs {
  input: GQLDeleteGalleryImageInput;
}


export interface GQLMutationDeleteInquiryArgs {
  input: GQLDeleteInquiryInput;
}


export interface GQLMutationDeleteJobCategoryArgs {
  input: GQLDeleteJobCategoryInput;
}


export interface GQLMutationDeleteNewsPostArgs {
  newsPostId: Scalars['ID'];
}


export interface GQLMutationDeletePaymentRequestArgs {
  input: GQLDeletePaymentRequestInput;
}


export interface GQLMutationDeletePhotographicCategoryArgs {
  input: GQLDeletePhotographicCategoryInput;
}


export interface GQLMutationDeleteStudioArgs {
  input: GQLDeleteStudioInput;
}


export interface GQLMutationDeleteStudioEntryArgs {
  input: GQLDeleteStudioEntryInput;
}


export interface GQLMutationDeleteStudioImageArgs {
  input: GQLDeleteStudioImageInput;
}


export interface GQLMutationForceVerifyEmailArgs {
  userId: Scalars['ID'];
}


export interface GQLMutationRegisterUserArgs {
  user: GQLRegisterUserInput;
}


export interface GQLMutationRequestPasswordResetArgs {
  email: Scalars['String'];
}


export interface GQLMutationResetPasswordArgs {
  password: Scalars['String'];
  token: Scalars['String'];
}


export interface GQLMutationSendDirectMessageArgs {
  text: Scalars['String'];
  to: Scalars['Float'];
}


export interface GQLMutationSigninArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}


export interface GQLMutationUpdatePhotographerProfileArgs {
  photographerProfile: GQLPhotographerProfileInput;
}


export interface GQLMutationUpdateProfileArgs {
  profile: GQLProfileInput;
}


export interface GQLMutationUpdateProfileImageArgs {
  image: Scalars['Upload'];
}


export interface GQLMutationUpdateStudioArgs {
  input: GQLUpdateStudioInput;
}


export interface GQLMutationVerifyEmailArgs {
  token: Scalars['String'];
}

export interface GQLNewsPost {
  __typename?: 'NewsPost';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface GQLPaginatedDeliverables {
  __typename?: 'PaginatedDeliverables';
  count: Scalars['Int'];
  items: Array<GQLDeliverable>;
}

export interface GQLPaginatedInquiry {
  __typename?: 'PaginatedInquiry';
  count: Scalars['Int'];
  items: Array<GQLInquiry>;
}

export interface GQLPaginatedJobComments {
  __typename?: 'PaginatedJobComments';
  count: Scalars['Int'];
  items: Array<GQLJobComment>;
}

export interface GQLPaginatedJobMessages {
  __typename?: 'PaginatedJobMessages';
  count: Scalars['Int'];
  items: Array<GQLJobMessage>;
}

export interface GQLPaginatedJobs {
  __typename?: 'PaginatedJobs';
  count: Scalars['Int'];
  items: Array<GQLJob>;
}

export interface GQLPaginatedNewsPosts {
  __typename?: 'PaginatedNewsPosts';
  count: Scalars['Int'];
  items: Array<GQLNewsPost>;
}

export interface GQLPaginatedPaymentRequest {
  __typename?: 'PaginatedPaymentRequest';
  count: Scalars['Int'];
  items: Array<GQLPaymentRequest>;
}

export interface GQLPaginatedPhotographerComments {
  __typename?: 'PaginatedPhotographerComments';
  count: Scalars['Int'];
  items: Array<GQLPhotographerComment>;
}

export interface GQLPaginatedStudio {
  __typename?: 'PaginatedStudio';
  count: Scalars['Int'];
  items: Array<GQLStudio>;
}

export interface GQLPaginatedStudioEntry {
  __typename?: 'PaginatedStudioEntry';
  count: Scalars['Int'];
  items: Array<GQLStudioEntry>;
}

export interface GQLPaginatedUsers {
  __typename?: 'PaginatedUsers';
  count: Scalars['Int'];
  items: Array<GQLUser>;
}

export interface GQLPaymentIntent {
  __typename?: 'PaymentIntent';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
}

export interface GQLPaymentMethod {
  __typename?: 'PaymentMethod';
  brand: Scalars['String'];
  expireMonth: Scalars['Int'];
  expireYear: Scalars['Int'];
  id: Scalars['ID'];
  last4Digits: Scalars['String'];
}

export interface GQLPaymentRequest {
  __typename?: 'PaymentRequest';
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  bankAccountType: Scalars['String'];
  bankName: Scalars['String'];
  branchName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: GQLUser;
  userId: Scalars['ID'];
}

export interface GQLPaymentRequestInput {
  paymentRequestId: Scalars['ID'];
}

export interface GQLPaymentRequestsInput {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}

export interface GQLPhotographerComment {
  __typename?: 'PhotographerComment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  photographer: GQLUser;
  photographerId: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: GQLUser;
  userId: Scalars['ID'];
}

export interface GQLPhotographerCommentInput {
  photographerId: Scalars['ID'];
  text: Scalars['String'];
}

export interface GQLPhotographerProfileInput {
  businessForm: GQLBusinessForm;
  description: Scalars['String'];
  equipmentNames: Array<Scalars['String']>;
  feeRangeEnd?: Maybe<Scalars['Float']>;
  feeRangeStart?: Maybe<Scalars['Float']>;
  photographer: Scalars['Boolean'];
  photographicCategoryNames: Array<Scalars['String']>;
  screenName: Scalars['String'];
  workArea: Scalars['String'];
}

export interface GQLPhotographicCategory {
  __typename?: 'PhotographicCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  screenName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<GQLUser>;
}

export interface GQLProfileImage {
  __typename?: 'ProfileImage';
  createdAt: Scalars['DateTime'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  size: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user: GQLUser;
  width: Scalars['Int'];
}

export interface GQLProfileInput {
  address: Scalars['String'];
  phoneNumber: Scalars['String'];
  screenName: Scalars['String'];
  zipcode: Scalars['String'];
}

export interface GQLQuery {
  __typename?: 'Query';
  allDeliverables: GQLPaginatedDeliverables;
  area?: Maybe<GQLArea>;
  areas: Array<GQLArea>;
  deliverable: GQLDeliverable;
  deliverablePreview: GQLDeliverablePreview;
  deliverablePreviews: Array<GQLDeliverablePreview>;
  deliverables: Array<GQLDeliverable>;
  directMessages: Array<GQLDirectMessage>;
  equipment?: Maybe<GQLEquipment>;
  equipmentList: Array<GQLEquipment>;
  galleryImages: Array<GQLGalleryImage>;
  inquiries: GQLPaginatedInquiry;
  inquiry: GQLInquiry;
  job: GQLJob;
  jobApplications: Array<GQLJobApplication>;
  jobCategories: Array<GQLJobCategory>;
  jobCategory?: Maybe<GQLJobCategory>;
  jobComments: GQLPaginatedJobComments;
  jobMessages: GQLPaginatedJobMessages;
  jobPaymentIntent: GQLPaymentIntent;
  jobs: GQLPaginatedJobs;
  nearJobs: Array<GQLJob>;
  newsPost: GQLNewsPost;
  newsPosts: GQLPaginatedNewsPosts;
  paymentMethods: Array<GQLPaymentMethod>;
  paymentRequest: GQLPaymentRequest;
  paymentRequests: GQLPaginatedPaymentRequest;
  photographerComments: GQLPaginatedPhotographerComments;
  photographers: GQLPaginatedUsers;
  photographicCategories: Array<GQLPhotographicCategory>;
  photographicCategory?: Maybe<GQLPhotographicCategory>;
  profile: GQLUser;
  studio: GQLStudio;
  studioEntries: GQLPaginatedStudioEntry;
  studioEntry: GQLStudioEntry;
  studioImages: Array<GQLStudioImage>;
  studios: GQLPaginatedStudio;
  user: GQLUser;
  users: GQLPaginatedUsers;
}


export interface GQLQueryAllDeliverablesArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface GQLQueryAreaArgs {
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
}


export interface GQLQueryAreasArgs {
  city?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
}


export interface GQLQueryDeliverableArgs {
  deliverableId: Scalars['ID'];
}


export interface GQLQueryDeliverablePreviewArgs {
  deliverablePreviewId: Scalars['ID'];
}


export interface GQLQueryDeliverablePreviewsArgs {
  jobId: Scalars['ID'];
}


export interface GQLQueryDeliverablesArgs {
  jobId: Scalars['ID'];
}


export interface GQLQueryDirectMessagesArgs {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}


export interface GQLQueryEquipmentArgs {
  name: Scalars['String'];
}


export interface GQLQueryGalleryImagesArgs {
  input: GQLGalleryImagesInput;
}


export interface GQLQueryInquiriesArgs {
  input: GQLInquiriesInput;
}


export interface GQLQueryInquiryArgs {
  input: GQLInquiryInput;
}


export interface GQLQueryJobArgs {
  jobId: Scalars['ID'];
}


export interface GQLQueryJobApplicationsArgs {
  jobId?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
}


export interface GQLQueryJobCategoryArgs {
  name: Scalars['String'];
}


export interface GQLQueryJobCommentsArgs {
  jobId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface GQLQueryJobMessagesArgs {
  jobId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface GQLQueryJobPaymentIntentArgs {
  jobId: Scalars['ID'];
}


export interface GQLQueryJobsArgs {
  businessForm?: Maybe<GQLBusinessForm>;
  categoryNames?: Maybe<Array<Scalars['String']>>;
  deadlineRange?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  maxFee?: Maybe<Scalars['Int']>;
  minFee?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  photographerNames?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
  userNames?: Maybe<Array<Scalars['String']>>;
}


export interface GQLQueryNearJobsArgs {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
}


export interface GQLQueryNewsPostArgs {
  newsPostId: Scalars['ID'];
}


export interface GQLQueryNewsPostsArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface GQLQueryPaymentRequestArgs {
  input: GQLPaymentRequestInput;
}


export interface GQLQueryPaymentRequestsArgs {
  input: GQLPaymentRequestsInput;
}


export interface GQLQueryPhotographerCommentsArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  photographerId: Scalars['ID'];
}


export interface GQLQueryPhotographersArgs {
  MaxReferenceFee?: Maybe<Scalars['Int']>;
  areaCodes?: Maybe<Array<Scalars['String']>>;
  equipmentNames?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  minReferenceFee?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  photographicCategoryNames?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
}


export interface GQLQueryPhotographicCategoryArgs {
  name: Scalars['String'];
}


export interface GQLQueryStudioArgs {
  input: GQLStudioInput;
}


export interface GQLQueryStudioEntriesArgs {
  input: GQLStudioEntriesInput;
}


export interface GQLQueryStudioEntryArgs {
  input: GQLStudioEntryInput;
}


export interface GQLQueryStudioImagesArgs {
  input: GQLStudioImagesInput;
}


export interface GQLQueryStudiosArgs {
  input: GQLStudiosInput;
}


export interface GQLQueryUserArgs {
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
}


export interface GQLQueryUsersArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface GQLRegisterUserInput {
  address: Scalars['String'];
  birthdate: Scalars['DateTime'];
  email: Scalars['String'];
  equipmentIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  screenName: Scalars['String'];
  zipcode: Scalars['String'];
}

/** Sex */
export enum GQLSex {
  Female = 'Female',
  Male = 'Male',
  NotApplicable = 'NotApplicable',
  NotKnown = 'NotKnown'
}

export interface GQLStudio {
  __typename?: 'Studio';
  address: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  images: Array<GQLStudioImage>;
  name: Scalars['String'];
  screenName: Scalars['String'];
  zipcode: Scalars['String'];
}

export interface GQLStudioEntriesInput {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}

export interface GQLStudioEntry {
  __typename?: 'StudioEntry';
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  id: Scalars['ID'];
  studioAddress: Scalars['String'];
  studioName: Scalars['String'];
  studioZipcode: Scalars['String'];
  text: Scalars['String'];
}

export interface GQLStudioEntryInput {
  studioEntryId: Scalars['ID'];
}

export interface GQLStudioImage {
  __typename?: 'StudioImage';
  createdAt: Scalars['DateTime'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  size: Scalars['Int'];
  studio: GQLStudio;
  studioId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width: Scalars['Int'];
}

export interface GQLStudioImagesInput {
  studioId: Scalars['String'];
}

export interface GQLStudioInput {
  studioId?: Maybe<Scalars['ID']>;
  studioName?: Maybe<Scalars['String']>;
}

export interface GQLStudiosInput {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}

export interface GQLToken {
  __typename?: 'Token';
  token: Scalars['String'];
}

export interface GQLUpdateStudioInput {
  address: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  screenName: Scalars['String'];
  studioId: Scalars['Int'];
  zipcode: Scalars['String'];
}


export interface GQLUser {
  __typename?: 'User';
  address: Scalars['String'];
  assignedJobs: Array<GQLJob>;
  birthYear?: Maybe<Scalars['Float']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  businessForm?: Maybe<GQLBusinessForm>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  entries: Array<GQLJobApplication>;
  equipment: Array<GQLEquipment>;
  feeRangeEnd?: Maybe<Scalars['Int']>;
  feeRangeStart?: Maybe<Scalars['Int']>;
  galleryImages: Array<GQLGalleryImage>;
  id: Scalars['ID'];
  jobComments: Array<GQLJobComment>;
  jobMessages: Array<GQLJobMessage>;
  jobs: Array<GQLJob>;
  name: Scalars['String'];
  passwordUpdatedAt: Scalars['DateTime'];
  paymentRequests: Array<GQLPaymentRequest>;
  phoneNumber: Scalars['String'];
  photographer: Scalars['Boolean'];
  photographicCategories: Array<GQLPhotographicCategory>;
  profileImage?: Maybe<GQLProfileImage>;
  profileImageId?: Maybe<Scalars['ID']>;
  receivedDirectMessages: Array<GQLDirectMessage>;
  screenName: Scalars['String'];
  sentDirectMessages: Array<GQLDirectMessage>;
  sex: GQLSex;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workArea: Scalars['String'];
  workCount: Scalars['Float'];
  zipcode: Scalars['String'];
}

export type GQLAreasQueryVariables = Exact<{
  prefecture: Scalars['String'];
}>;


export type GQLAreasQuery = (
  { __typename?: 'Query' }
  & { areas: Array<(
    { __typename?: 'Area' }
    & GQLAreaFragment
  )> }
);

export type GQLAreaByNameQueryVariables = Exact<{
  prefecture: Scalars['String'];
  city: Scalars['String'];
}>;


export type GQLAreaByNameQuery = (
  { __typename?: 'Query' }
  & { area?: Maybe<(
    { __typename?: 'Area' }
    & GQLAreaFragment
  )> }
);

export type GQLAreaByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GQLAreaByCodeQuery = (
  { __typename?: 'Query' }
  & { area?: Maybe<(
    { __typename?: 'Area' }
    & GQLAreaFragment
  )> }
);

export type GQLSigninMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type GQLSigninMutation = (
  { __typename?: 'Mutation' }
  & { signin: (
    { __typename?: 'Token' }
    & Pick<GQLToken, 'token'>
  ) }
);

export type GQLChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type GQLChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'changePassword'>
);

export type GQLRequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GQLRequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'requestPasswordReset'>
);

export type GQLResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type GQLResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'resetPassword'>
);

export type GQLVerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type GQLVerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'verifyEmail'>
);

export type GQLForceVerifyEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GQLForceVerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { forceVerifyEmail: (
    { __typename?: 'User' }
    & GQLFullUserFragment
  ) }
);

export type GQLAddPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type GQLAddPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { addPaymentMethod: (
    { __typename?: 'PaymentMethod' }
    & GQLPaymentMethodFragment
  ) }
);

export type GQLPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<(
    { __typename?: 'PaymentMethod' }
    & GQLPaymentMethodFragment
  )> }
);

export type GQLUserFragment = (
  { __typename?: 'User' }
  & Pick<GQLUser, 'id' | 'name' | 'screenName' | 'description' | 'profileImageId'>
  & { profileImage?: Maybe<(
    { __typename?: 'ProfileImage' }
    & Pick<GQLProfileImage, 'width' | 'height' | 'url'>
  )> }
);

export type GQLFullUserFragment = (
  { __typename?: 'User' }
  & Pick<GQLUser, 'email' | 'emailVerified' | 'sex' | 'birthdate' | 'phoneNumber' | 'zipcode' | 'address' | 'stripeCustomerId' | 'businessForm' | 'photographer' | 'workArea' | 'feeRangeStart' | 'feeRangeEnd' | 'createdAt' | 'updatedAt' | 'passwordUpdatedAt'>
  & GQLUserFragment
);

export type GQLPhotographerFragment = (
  { __typename?: 'User' }
  & Pick<GQLUser, 'feeRangeStart' | 'feeRangeEnd' | 'workArea' | 'birthYear' | 'workCount'>
  & { photographicCategories: Array<(
    { __typename?: 'PhotographicCategory' }
    & Pick<GQLPhotographicCategory, 'name' | 'screenName'>
  )>, equipment: Array<(
    { __typename?: 'Equipment' }
    & Pick<GQLEquipment, 'name' | 'screenName'>
  )> }
  & GQLUserFragment
);

export type GQLProfileFragment = (
  { __typename?: 'User' }
  & Pick<GQLUser, 'feeRangeStart' | 'feeRangeEnd' | 'workArea' | 'birthYear' | 'workCount' | 'photographer' | 'phoneNumber' | 'zipcode' | 'address' | 'email' | 'businessForm'>
  & { photographicCategories: Array<(
    { __typename?: 'PhotographicCategory' }
    & Pick<GQLPhotographicCategory, 'name'>
  )>, equipment: Array<(
    { __typename?: 'Equipment' }
    & Pick<GQLEquipment, 'name'>
  )> }
  & GQLUserFragment
);

export type GQLUserWithJobsFragment = (
  { __typename?: 'User' }
  & { jobs: Array<(
    { __typename?: 'Job' }
    & GQLJobFragment
  )> }
  & GQLUserFragment
);

export type GQLJobFragment = (
  { __typename?: 'Job' }
  & Pick<GQLJob, 'id' | 'name' | 'fee' | 'description' | 'deadline' | 'address' | 'lat' | 'lng' | 'applicationCount' | 'applied' | 'assigned' | 'own' | 'status' | 'createdAt' | 'updatedAt'>
  & { photographer?: Maybe<(
    { __typename?: 'User' }
    & GQLUserFragment
  )>, area: (
    { __typename?: 'Area' }
    & GQLAreaFragment
  ), categories: Array<(
    { __typename?: 'JobCategory' }
    & Pick<GQLJobCategory, 'name' | 'screenName'>
  )> }
);

export type GQLJobCommentFragment = (
  { __typename?: 'JobComment' }
  & Pick<GQLJobComment, 'id' | 'text' | 'createdAt' | 'updatedAt'>
  & { user: (
    { __typename?: 'User' }
    & GQLUserFragment
  ) }
);

export type GQLPhotographerCommentFragment = (
  { __typename?: 'PhotographerComment' }
  & Pick<GQLPhotographerComment, 'id' | 'text' | 'createdAt' | 'updatedAt' | 'photographerId' | 'userId'>
  & { photographer: (
    { __typename?: 'User' }
    & GQLPhotographerFragment
  ), user: (
    { __typename?: 'User' }
    & GQLUserFragment
  ) }
);

export type GQLJobApplicationFragment = (
  { __typename?: 'JobApplication' }
  & Pick<GQLJobApplication, 'jobId' | 'userId' | 'createdAt' | 'updatedAt'>
);

export type GQLAreaFragment = (
  { __typename?: 'Area' }
  & Pick<GQLArea, 'code' | 'prefecture' | 'city'>
);

export type GQLDeliverableFragment = (
  { __typename?: 'Deliverable' }
  & Pick<GQLDeliverable, 'id' | 'originalFileName' | 'url' | 'size' | 'jobId' | 'createdAt' | 'updatedAt'>
);

export type GQLDeliverablePreviewFragment = (
  { __typename?: 'DeliverablePreview' }
  & Pick<GQLDeliverablePreview, 'id' | 'size' | 'width' | 'height' | 'url' | 'createdAt' | 'updatedAt'>
);

export type GQLPaymentMethodFragment = (
  { __typename?: 'PaymentMethod' }
  & Pick<GQLPaymentMethod, 'id' | 'brand' | 'expireMonth' | 'expireYear' | 'last4Digits'>
);

export type GQLNewsPostFragment = (
  { __typename?: 'NewsPost' }
  & Pick<GQLNewsPost, 'id' | 'title' | 'content' | 'createdAt' | 'updatedAt'>
);

export type GQLGalleryImageFragment = (
  { __typename?: 'GalleryImage' }
  & Pick<GQLGalleryImage, 'id' | 'url' | 'width' | 'height' | 'userId'>
);

export type GQLGalleryImagesQueryVariables = Exact<{
  input: GQLGalleryImagesInput;
}>;


export type GQLGalleryImagesQuery = (
  { __typename?: 'Query' }
  & { galleryImages: Array<(
    { __typename?: 'GalleryImage' }
    & GQLGalleryImageFragment
  )> }
);

export type GQLAddGalleryImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type GQLAddGalleryImageMutation = (
  { __typename?: 'Mutation' }
  & { addGalleryImage: (
    { __typename?: 'GalleryImage' }
    & GQLGalleryImageFragment
  ) }
);

export type GQLDeleteGalleryImageMutationVariables = Exact<{
  input: GQLDeleteGalleryImageInput;
}>;


export type GQLDeleteGalleryImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteGalleryImage'>
);

export type GQLInquiryFragment = (
  { __typename?: 'Inquiry' }
  & Pick<GQLInquiry, 'id' | 'name' | 'email' | 'phoneNumber' | 'text'>
);

export type GQLInquiryQueryVariables = Exact<{
  input: GQLInquiryInput;
}>;


export type GQLInquiryQuery = (
  { __typename?: 'Query' }
  & { inquiry: (
    { __typename?: 'Inquiry' }
    & GQLInquiryFragment
  ) }
);

export type GQLInquiriesQueryVariables = Exact<{
  input: GQLInquiriesInput;
}>;


export type GQLInquiriesQuery = (
  { __typename?: 'Query' }
  & { inquiries: (
    { __typename?: 'PaginatedInquiry' }
    & Pick<GQLPaginatedInquiry, 'count'>
    & { items: Array<(
      { __typename?: 'Inquiry' }
      & GQLInquiryFragment
    )> }
  ) }
);

export type GQLCreateInquiryMutationVariables = Exact<{
  input: GQLCreateInquiryInput;
}>;


export type GQLCreateInquiryMutation = (
  { __typename?: 'Mutation' }
  & { createInquiry: (
    { __typename?: 'Inquiry' }
    & GQLInquiryFragment
  ) }
);

export type GQLDeleteInquiryMutationVariables = Exact<{
  input: GQLDeleteInquiryInput;
}>;


export type GQLDeleteInquiryMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteInquiry'>
);

export type GQLCreateJobMutationVariables = Exact<{
  job: GQLJobInput;
}>;


export type GQLCreateJobMutation = (
  { __typename?: 'Mutation' }
  & { createJob: (
    { __typename?: 'Job' }
    & GQLJobFragment
  ) }
);

export type GQLJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLJobQuery = (
  { __typename?: 'Query' }
  & { job: (
    { __typename?: 'Job' }
    & { user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ), photographer?: Maybe<(
      { __typename?: 'User' }
      & { equipment: Array<(
        { __typename?: 'Equipment' }
        & Pick<GQLEquipment, 'name' | 'screenName'>
      )> }
      & GQLPhotographerFragment
    )> }
    & GQLJobFragment
  ) }
);

export type GQLJobPaymentIntentQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLJobPaymentIntentQuery = (
  { __typename?: 'Query' }
  & { jobPaymentIntent: (
    { __typename?: 'PaymentIntent' }
    & Pick<GQLPaymentIntent, 'clientSecret' | 'amount'>
  ) }
);

export type GQLJobsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  businessForm?: Maybe<GQLBusinessForm>;
  categoryNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  deadlineRange?: Maybe<Scalars['Int']>;
  maxFee?: Maybe<Scalars['Int']>;
  minFee?: Maybe<Scalars['Int']>;
  userNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  photographerNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GQLJobsQuery = (
  { __typename?: 'Query' }
  & { jobs: (
    { __typename?: 'PaginatedJobs' }
    & Pick<GQLPaginatedJobs, 'count'>
    & { items: Array<(
      { __typename?: 'Job' }
      & { user: (
        { __typename?: 'User' }
        & GQLUserFragment
      ), photographer?: Maybe<(
        { __typename?: 'User' }
        & GQLPhotographerFragment
      )> }
      & GQLJobFragment
    )> }
  ) }
);

export type GQLJobCommentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  jobId: Scalars['ID'];
}>;


export type GQLJobCommentsQuery = (
  { __typename?: 'Query' }
  & { jobComments: (
    { __typename?: 'PaginatedJobComments' }
    & Pick<GQLPaginatedJobComments, 'count'>
    & { items: Array<(
      { __typename?: 'JobComment' }
      & GQLJobCommentFragment
    )> }
  ) }
);

export type GQLEquipmentListQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLEquipmentListQuery = (
  { __typename?: 'Query' }
  & { equipmentList: Array<(
    { __typename?: 'Equipment' }
    & Pick<GQLEquipment, 'name' | 'screenName'>
  )> }
);

export type GQLJobCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLJobCategoriesQuery = (
  { __typename?: 'Query' }
  & { jobCategories: Array<(
    { __typename?: 'JobCategory' }
    & Pick<GQLJobCategory, 'name' | 'screenName'>
  )> }
);

export type GQLAddJobCommentMutationVariables = Exact<{
  jobCommentInput: GQLJobCommentInput;
}>;


export type GQLAddJobCommentMutation = (
  { __typename?: 'Mutation' }
  & { addJobComment: (
    { __typename?: 'JobComment' }
    & Pick<GQLJobComment, 'text' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ) }
  ) }
);

export type GQLAddJobMessageMutationVariables = Exact<{
  jobMessageInput: GQLJobMessageInput;
}>;


export type GQLAddJobMessageMutation = (
  { __typename?: 'Mutation' }
  & { addJobMessage: (
    { __typename?: 'JobMessage' }
    & Pick<GQLJobMessage, 'text' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ) }
  ) }
);

export type GQLJobMessagesQueryVariables = Exact<{
  jobId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GQLJobMessagesQuery = (
  { __typename?: 'Query' }
  & { jobMessages: (
    { __typename?: 'PaginatedJobMessages' }
    & Pick<GQLPaginatedJobMessages, 'count'>
    & { items: Array<(
      { __typename?: 'JobMessage' }
      & Pick<GQLJobMessage, 'id' | 'text' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & GQLUserFragment
      ) }
    )> }
  ) }
);

export type GQLJobApplicationsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLJobApplicationsQuery = (
  { __typename?: 'Query' }
  & { jobApplications: Array<(
    { __typename?: 'JobApplication' }
    & { user: (
      { __typename?: 'User' }
      & GQLPhotographerFragment
    ) }
    & GQLJobApplicationFragment
  )> }
);

export type GQLJobApplicationsByUserNameQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type GQLJobApplicationsByUserNameQuery = (
  { __typename?: 'Query' }
  & { jobApplications: Array<(
    { __typename?: 'JobApplication' }
    & { job: (
      { __typename?: 'Job' }
      & { user: (
        { __typename?: 'User' }
        & GQLUserFragment
      ), photographer?: Maybe<(
        { __typename?: 'User' }
        & GQLPhotographerFragment
      )> }
      & GQLJobFragment
    ) }
    & GQLJobApplicationFragment
  )> }
);

export type GQLCancelApplyForJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLCancelApplyForJobMutation = (
  { __typename?: 'Mutation' }
  & { cancelApplyForJob?: Maybe<(
    { __typename?: 'JobApplication' }
    & { job: (
      { __typename?: 'Job' }
      & GQLJobFragment
    ) }
    & GQLJobApplicationFragment
  )> }
);

export type GQLAcceptJobApplicationMutationVariables = Exact<{
  jobId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GQLAcceptJobApplicationMutation = (
  { __typename?: 'Mutation' }
  & { acceptJobApplication: (
    { __typename?: 'JobApplication' }
    & { job: (
      { __typename?: 'Job' }
      & GQLJobFragment
    ) }
    & GQLJobApplicationFragment
  ) }
);

export type GQLApplyForJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLApplyForJobMutation = (
  { __typename?: 'Mutation' }
  & { applyForJob: (
    { __typename?: 'JobApplication' }
    & { job: (
      { __typename?: 'Job' }
      & GQLJobFragment
    ), user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ) }
    & GQLJobApplicationFragment
  ) }
);

export type GQLAddDeliverablePreviewMutationVariables = Exact<{
  jobId: Scalars['ID'];
  image: Scalars['Upload'];
}>;


export type GQLAddDeliverablePreviewMutation = (
  { __typename?: 'Mutation' }
  & { addDeliverablePreview: (
    { __typename?: 'DeliverablePreview' }
    & GQLDeliverablePreviewFragment
  ) }
);

export type GQLDeliverablePreviewsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLDeliverablePreviewsQuery = (
  { __typename?: 'Query' }
  & { deliverablePreviews: Array<(
    { __typename?: 'DeliverablePreview' }
    & GQLDeliverablePreviewFragment
  )> }
);

export type GQLAcceptDeliverablePreviewsMutationVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLAcceptDeliverablePreviewsMutation = (
  { __typename?: 'Mutation' }
  & { acceptDeliverablePreviews: (
    { __typename?: 'Job' }
    & GQLJobFragment
  ) }
);

export type GQLAddDeliverableMutationVariables = Exact<{
  jobId: Scalars['ID'];
  data: Scalars['Upload'];
}>;


export type GQLAddDeliverableMutation = (
  { __typename?: 'Mutation' }
  & { addDeliverable: (
    { __typename?: 'Deliverable' }
    & GQLDeliverableFragment
  ) }
);

export type GQLDeliverablesQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GQLDeliverablesQuery = (
  { __typename?: 'Query' }
  & { deliverables: Array<(
    { __typename?: 'Deliverable' }
    & GQLDeliverableFragment
  )> }
);

export type GQLAllDeliverablesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GQLAllDeliverablesQuery = (
  { __typename?: 'Query' }
  & { allDeliverables: (
    { __typename?: 'PaginatedDeliverables' }
    & Pick<GQLPaginatedDeliverables, 'count'>
    & { items: Array<(
      { __typename?: 'Deliverable' }
      & GQLDeliverableFragment
    )> }
  ) }
);

export type GQLDeleteDeliverableMutationVariables = Exact<{
  deliverableId: Scalars['ID'];
}>;


export type GQLDeleteDeliverableMutation = (
  { __typename?: 'Mutation' }
  & { deleteDeliverable?: Maybe<Array<(
    { __typename?: 'Deliverable' }
    & GQLDeliverableFragment
  )>> }
);

export type GQLNearJobsQueryVariables = Exact<{
  lat: Scalars['Float'];
  lng: Scalars['Float'];
}>;


export type GQLNearJobsQuery = (
  { __typename?: 'Query' }
  & { nearJobs: Array<(
    { __typename?: 'Job' }
    & GQLJobFragment
  )> }
);

export type GQLNewsPostQueryVariables = Exact<{
  newsPostId: Scalars['ID'];
}>;


export type GQLNewsPostQuery = (
  { __typename?: 'Query' }
  & { newsPost: (
    { __typename?: 'NewsPost' }
    & GQLNewsPostFragment
  ) }
);

export type GQLNewsPostsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GQLNewsPostsQuery = (
  { __typename?: 'Query' }
  & { newsPosts: (
    { __typename?: 'PaginatedNewsPosts' }
    & Pick<GQLPaginatedNewsPosts, 'count'>
    & { items: Array<(
      { __typename?: 'NewsPost' }
      & GQLNewsPostFragment
    )> }
  ) }
);

export type GQLAddNewsPostMutationVariables = Exact<{
  addNewsPostInput: GQLAddNewsPostInput;
}>;


export type GQLAddNewsPostMutation = (
  { __typename?: 'Mutation' }
  & { addNewsPost: (
    { __typename?: 'NewsPost' }
    & GQLNewsPostFragment
  ) }
);

export type GQLDeleteNewsPostMutationVariables = Exact<{
  newsPostId: Scalars['ID'];
}>;


export type GQLDeleteNewsPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteNewsPost'>
);

export type GQLPaymentRequestFragment = (
  { __typename?: 'PaymentRequest' }
  & Pick<GQLPaymentRequest, 'id' | 'bankName' | 'branchName' | 'bankAccountType' | 'bankAccountNumber' | 'bankAccountName' | 'createdAt' | 'userId'>
);

export type GQLPaymentRequestQueryVariables = Exact<{
  input: GQLPaymentRequestInput;
}>;


export type GQLPaymentRequestQuery = (
  { __typename?: 'Query' }
  & { paymentRequest: (
    { __typename?: 'PaymentRequest' }
    & { user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ) }
    & GQLPaymentRequestFragment
  ) }
);

export type GQLPaymentRequestsQueryVariables = Exact<{
  input: GQLPaymentRequestsInput;
}>;


export type GQLPaymentRequestsQuery = (
  { __typename?: 'Query' }
  & { paymentRequests: (
    { __typename?: 'PaginatedPaymentRequest' }
    & Pick<GQLPaginatedPaymentRequest, 'count'>
    & { items: Array<(
      { __typename?: 'PaymentRequest' }
      & { user: (
        { __typename?: 'User' }
        & GQLUserFragment
      ) }
      & GQLPaymentRequestFragment
    )> }
  ) }
);

export type GQLCreatePaymentRequestMutationVariables = Exact<{
  input: GQLCreatePaymentRequestInput;
}>;


export type GQLCreatePaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentRequest: (
    { __typename?: 'PaymentRequest' }
    & GQLPaymentRequestFragment
  ) }
);

export type GQLDeletePaymentRequestMutationVariables = Exact<{
  input: GQLDeletePaymentRequestInput;
}>;


export type GQLDeletePaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deletePaymentRequest'>
);

export type GQLPhotographicCategoryFragment = (
  { __typename?: 'PhotographicCategory' }
  & Pick<GQLPhotographicCategory, 'id' | 'name' | 'screenName' | 'createdAt'>
);

export type GQLPhotographicCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLPhotographicCategoriesQuery = (
  { __typename?: 'Query' }
  & { photographicCategories: Array<(
    { __typename?: 'PhotographicCategory' }
    & GQLPhotographicCategoryFragment
  )> }
);

export type GQLAddPhotographicCategoryMutationVariables = Exact<{
  input: GQLAddPhotographicCategoryInput;
}>;


export type GQLAddPhotographicCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addPhotographicCategory: (
    { __typename?: 'PhotographicCategory' }
    & GQLPhotographicCategoryFragment
  ) }
);

export type GQLDeletePhotographicCategoryMutationVariables = Exact<{
  input: GQLDeletePhotographicCategoryInput;
}>;


export type GQLDeletePhotographicCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deletePhotographicCategory'>
);

export type GQLPhotographersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  photographicCategories?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;


export type GQLPhotographersQuery = (
  { __typename?: 'Query' }
  & { photographers: (
    { __typename?: 'PaginatedUsers' }
    & Pick<GQLPaginatedUsers, 'count'>
    & { items: Array<(
      { __typename?: 'User' }
      & GQLPhotographerFragment
    )> }
  ) }
);

export type GQLPhotographerQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type GQLPhotographerQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & GQLPhotographerFragment
  ) }
);

export type GQLPhotographerCommentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  photographerId: Scalars['ID'];
}>;


export type GQLPhotographerCommentsQuery = (
  { __typename?: 'Query' }
  & { photographerComments: (
    { __typename?: 'PaginatedPhotographerComments' }
    & Pick<GQLPaginatedPhotographerComments, 'count'>
    & { items: Array<(
      { __typename?: 'PhotographerComment' }
      & GQLPhotographerCommentFragment
    )> }
  ) }
);

export type GQLUpdatePhotographerProfileMutationVariables = Exact<{
  photographerProfile: GQLPhotographerProfileInput;
}>;


export type GQLUpdatePhotographerProfileMutation = (
  { __typename?: 'Mutation' }
  & { updatePhotographerProfile: (
    { __typename?: 'User' }
    & GQLProfileFragment
  ) }
);

export type GQLAddPhotographerCommentMutationVariables = Exact<{
  photographerCommentInput: GQLPhotographerCommentInput;
}>;


export type GQLAddPhotographerCommentMutation = (
  { __typename?: 'Mutation' }
  & { addPhotographerComment: (
    { __typename?: 'PhotographerComment' }
    & Pick<GQLPhotographerComment, 'text' | 'createdAt' | 'updatedAt' | 'photographerId' | 'userId'>
    & { photographer: (
      { __typename?: 'User' }
      & GQLPhotographerFragment
    ), user: (
      { __typename?: 'User' }
      & GQLUserFragment
    ) }
  ) }
);

export type GQLStudioEntryFragment = (
  { __typename?: 'StudioEntry' }
  & Pick<GQLStudioEntry, 'id' | 'contactName' | 'contactEmail' | 'studioName' | 'studioZipcode' | 'studioAddress' | 'contactPhone' | 'text'>
);

export type GQLStudioEntryQueryVariables = Exact<{
  input: GQLStudioEntryInput;
}>;


export type GQLStudioEntryQuery = (
  { __typename?: 'Query' }
  & { studioEntry: (
    { __typename?: 'StudioEntry' }
    & GQLStudioEntryFragment
  ) }
);

export type GQLStudioEntriesQueryVariables = Exact<{
  input: GQLStudioEntriesInput;
}>;


export type GQLStudioEntriesQuery = (
  { __typename?: 'Query' }
  & { studioEntries: (
    { __typename?: 'PaginatedStudioEntry' }
    & Pick<GQLPaginatedStudioEntry, 'count'>
    & { items: Array<(
      { __typename?: 'StudioEntry' }
      & GQLStudioEntryFragment
    )> }
  ) }
);

export type GQLCreateStudioEntryMutationVariables = Exact<{
  input: GQLCreateStudioEntryInput;
}>;


export type GQLCreateStudioEntryMutation = (
  { __typename?: 'Mutation' }
  & { createStudioEntry: (
    { __typename?: 'StudioEntry' }
    & GQLStudioEntryFragment
  ) }
);

export type GQLDeleteStudioEntryMutationVariables = Exact<{
  input: GQLDeleteStudioEntryInput;
}>;


export type GQLDeleteStudioEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteStudioEntry'>
);

export type GQLStudioImageFragment = (
  { __typename?: 'StudioImage' }
  & Pick<GQLStudioImage, 'id' | 'url' | 'width' | 'height' | 'size'>
);

export type GQLStudioImagesQueryVariables = Exact<{
  input: GQLStudioImagesInput;
}>;


export type GQLStudioImagesQuery = (
  { __typename?: 'Query' }
  & { studioImages: Array<(
    { __typename?: 'StudioImage' }
    & GQLStudioImageFragment
  )> }
);

export type GQLAddStudioImageMutationVariables = Exact<{
  input: GQLAddStudioImageInput;
  image: Scalars['Upload'];
}>;


export type GQLAddStudioImageMutation = (
  { __typename?: 'Mutation' }
  & { addStudioImage: (
    { __typename?: 'StudioImage' }
    & GQLStudioImageFragment
  ) }
);

export type GQLDeleteStudioImageMutationVariables = Exact<{
  input: GQLDeleteStudioImageInput;
}>;


export type GQLDeleteStudioImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteStudioImage'>
);

export type GQLStudioFragment = (
  { __typename?: 'Studio' }
  & Pick<GQLStudio, 'id' | 'name' | 'screenName' | 'description' | 'zipcode' | 'address'>
  & { images: Array<(
    { __typename?: 'StudioImage' }
    & GQLStudioImageFragment
  )> }
);

export type GQLFullStudioFragment = (
  { __typename?: 'Studio' }
  & Pick<GQLStudio, 'email'>
  & GQLStudioFragment
);

export type GQLStudioQueryVariables = Exact<{
  input: GQLStudioInput;
}>;


export type GQLStudioQuery = (
  { __typename?: 'Query' }
  & { studio: (
    { __typename?: 'Studio' }
    & GQLStudioFragment
  ) }
);

export type GQLFullStudioQueryVariables = Exact<{
  input: GQLStudioInput;
}>;


export type GQLFullStudioQuery = (
  { __typename?: 'Query' }
  & { studio: (
    { __typename?: 'Studio' }
    & GQLFullStudioFragment
  ) }
);

export type GQLStudiosQueryVariables = Exact<{
  input: GQLStudiosInput;
}>;


export type GQLStudiosQuery = (
  { __typename?: 'Query' }
  & { studios: (
    { __typename?: 'PaginatedStudio' }
    & Pick<GQLPaginatedStudio, 'count'>
    & { items: Array<(
      { __typename?: 'Studio' }
      & GQLStudioFragment
    )> }
  ) }
);

export type GQLFullStudiosQueryVariables = Exact<{
  input: GQLStudiosInput;
}>;


export type GQLFullStudiosQuery = (
  { __typename?: 'Query' }
  & { studios: (
    { __typename?: 'PaginatedStudio' }
    & Pick<GQLPaginatedStudio, 'count'>
    & { items: Array<(
      { __typename?: 'Studio' }
      & GQLFullStudioFragment
    )> }
  ) }
);

export type GQLCreateStudioMutationVariables = Exact<{
  input: GQLCreateStudioInput;
}>;


export type GQLCreateStudioMutation = (
  { __typename?: 'Mutation' }
  & { createStudio: (
    { __typename?: 'Studio' }
    & GQLFullStudioFragment
  ) }
);

export type GQLUpdateStudioMutationVariables = Exact<{
  input: GQLUpdateStudioInput;
}>;


export type GQLUpdateStudioMutation = (
  { __typename?: 'Mutation' }
  & { updateStudio: (
    { __typename?: 'Studio' }
    & GQLFullStudioFragment
  ) }
);

export type GQLDeleteStudioMutationVariables = Exact<{
  input: GQLDeleteStudioInput;
}>;


export type GQLDeleteStudioMutation = (
  { __typename?: 'Mutation' }
  & Pick<GQLMutation, 'deleteStudio'>
);

export type GQLUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
}>;


export type GQLUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & GQLUserFragment
  ) }
);

export type GQLUserWithJobsQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type GQLUserWithJobsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { jobs: Array<(
      { __typename?: 'Job' }
      & { user: (
        { __typename?: 'User' }
        & GQLUserFragment
      ), photographer?: Maybe<(
        { __typename?: 'User' }
        & GQLPhotographerFragment
      )> }
      & GQLJobFragment
    )> }
    & GQLUserFragment
  ) }
);

export type GQLFullUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
}>;


export type GQLFullUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & GQLFullUserFragment
  ) }
);

export type GQLFullUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GQLFullUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'PaginatedUsers' }
    & Pick<GQLPaginatedUsers, 'count'>
    & { items: Array<(
      { __typename?: 'User' }
      & GQLFullUserFragment
    )> }
  ) }
);

export type GQLRegisterUserMutationVariables = Exact<{
  user: GQLRegisterUserInput;
}>;


export type GQLRegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'User' }
    & Pick<GQLUser, 'id' | 'email' | 'name' | 'screenName' | 'sex' | 'birthdate' | 'phoneNumber' | 'zipcode' | 'address' | 'description' | 'createdAt' | 'updatedAt'>
  ) }
);

export type GQLProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GQLProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'User' }
    & GQLProfileFragment
  ) }
);

export type GQLUpdateProfileMutationVariables = Exact<{
  profile: GQLProfileInput;
}>;


export type GQLUpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'User' }
    & GQLProfileFragment
  ) }
);

export type GQLUpdateProfileImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type GQLUpdateProfileImageMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileImage: (
    { __typename?: 'ProfileImage' }
    & Pick<GQLProfileImage, 'url' | 'width' | 'height' | 'size' | 'createdAt'>
  ) }
);

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  screenName
  description
  profileImage {
    width
    height
    url
  }
  profileImageId
}
    `;
export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  ...User
  email
  emailVerified
  sex
  birthdate
  phoneNumber
  zipcode
  address
  stripeCustomerId
  businessForm
  photographer
  workArea
  feeRangeStart
  feeRangeEnd
  createdAt
  updatedAt
  passwordUpdatedAt
}
    ${UserFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on User {
  ...User
  feeRangeStart
  feeRangeEnd
  workArea
  birthYear
  workCount
  photographer
  phoneNumber
  zipcode
  address
  email
  businessForm
  photographicCategories {
    name
  }
  equipment {
    name
  }
}
    ${UserFragmentDoc}`;
export const AreaFragmentDoc = gql`
    fragment Area on Area {
  code
  prefecture
  city
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on Job {
  id
  name
  fee
  description
  deadline
  address
  lat
  lng
  applicationCount
  applied
  assigned
  own
  status
  createdAt
  updatedAt
  photographer {
    ...User
  }
  area {
    ...Area
  }
  categories {
    name
    screenName
  }
}
    ${UserFragmentDoc}
${AreaFragmentDoc}`;
export const UserWithJobsFragmentDoc = gql`
    fragment UserWithJobs on User {
  ...User
  jobs {
    ...Job
  }
}
    ${UserFragmentDoc}
${JobFragmentDoc}`;
export const JobCommentFragmentDoc = gql`
    fragment JobComment on JobComment {
  id
  text
  createdAt
  updatedAt
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const PhotographerFragmentDoc = gql`
    fragment Photographer on User {
  ...User
  feeRangeStart
  feeRangeEnd
  workArea
  birthYear
  workCount
  photographicCategories {
    name
    screenName
  }
  equipment {
    name
    screenName
  }
}
    ${UserFragmentDoc}`;
export const PhotographerCommentFragmentDoc = gql`
    fragment PhotographerComment on PhotographerComment {
  id
  text
  createdAt
  updatedAt
  photographer {
    ...Photographer
  }
  photographerId
  user {
    ...User
  }
  userId
}
    ${PhotographerFragmentDoc}
${UserFragmentDoc}`;
export const JobApplicationFragmentDoc = gql`
    fragment JobApplication on JobApplication {
  jobId
  userId
  createdAt
  updatedAt
}
    `;
export const DeliverableFragmentDoc = gql`
    fragment Deliverable on Deliverable {
  id
  originalFileName
  url
  size
  jobId
  createdAt
  updatedAt
}
    `;
export const DeliverablePreviewFragmentDoc = gql`
    fragment DeliverablePreview on DeliverablePreview {
  id
  size
  width
  height
  url
  createdAt
  updatedAt
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment PaymentMethod on PaymentMethod {
  id
  brand
  expireMonth
  expireYear
  last4Digits
}
    `;
export const NewsPostFragmentDoc = gql`
    fragment NewsPost on NewsPost {
  id
  title
  content
  createdAt
  updatedAt
}
    `;
export const GalleryImageFragmentDoc = gql`
    fragment GalleryImage on GalleryImage {
  id
  url
  width
  height
  userId
}
    `;
export const InquiryFragmentDoc = gql`
    fragment Inquiry on Inquiry {
  id
  name
  email
  phoneNumber
  text
}
    `;
export const PaymentRequestFragmentDoc = gql`
    fragment PaymentRequest on PaymentRequest {
  id
  bankName
  branchName
  bankAccountType
  bankAccountNumber
  bankAccountName
  createdAt
  userId
}
    `;
export const PhotographicCategoryFragmentDoc = gql`
    fragment PhotographicCategory on PhotographicCategory {
  id
  name
  screenName
  createdAt
}
    `;
export const StudioEntryFragmentDoc = gql`
    fragment StudioEntry on StudioEntry {
  id
  contactName
  contactEmail
  studioName
  studioZipcode
  studioAddress
  contactPhone
  text
}
    `;
export const StudioImageFragmentDoc = gql`
    fragment StudioImage on StudioImage {
  id
  url
  width
  height
  size
}
    `;
export const StudioFragmentDoc = gql`
    fragment Studio on Studio {
  id
  name
  screenName
  description
  zipcode
  address
  images {
    ...StudioImage
  }
}
    ${StudioImageFragmentDoc}`;
export const FullStudioFragmentDoc = gql`
    fragment FullStudio on Studio {
  email
  ...Studio
}
    ${StudioFragmentDoc}`;
export const AreasDocument = gql`
    query areas($prefecture: String!) {
  areas(prefecture: $prefecture) {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useAreasQuery__
 *
 * To run a query within a React component, call `useAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreasQuery({
 *   variables: {
 *      prefecture: // value for 'prefecture'
 *   },
 * });
 */
export function useAreasQuery(baseOptions: Apollo.QueryHookOptions<GQLAreasQuery, GQLAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLAreasQuery, GQLAreasQueryVariables>(AreasDocument, options);
      }
export function useAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLAreasQuery, GQLAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLAreasQuery, GQLAreasQueryVariables>(AreasDocument, options);
        }
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>;
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>;
export type AreasQueryResult = Apollo.QueryResult<GQLAreasQuery, GQLAreasQueryVariables>;
export const AreaByNameDocument = gql`
    query areaByName($prefecture: String!, $city: String!) {
  area(prefecture: $prefecture, city: $city) {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useAreaByNameQuery__
 *
 * To run a query within a React component, call `useAreaByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaByNameQuery({
 *   variables: {
 *      prefecture: // value for 'prefecture'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useAreaByNameQuery(baseOptions: Apollo.QueryHookOptions<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>(AreaByNameDocument, options);
      }
export function useAreaByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>(AreaByNameDocument, options);
        }
export type AreaByNameQueryHookResult = ReturnType<typeof useAreaByNameQuery>;
export type AreaByNameLazyQueryHookResult = ReturnType<typeof useAreaByNameLazyQuery>;
export type AreaByNameQueryResult = Apollo.QueryResult<GQLAreaByNameQuery, GQLAreaByNameQueryVariables>;
export const AreaByCodeDocument = gql`
    query areaByCode($code: String!) {
  area(code: $code) {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useAreaByCodeQuery__
 *
 * To run a query within a React component, call `useAreaByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAreaByCodeQuery(baseOptions: Apollo.QueryHookOptions<GQLAreaByCodeQuery, GQLAreaByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLAreaByCodeQuery, GQLAreaByCodeQueryVariables>(AreaByCodeDocument, options);
      }
export function useAreaByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLAreaByCodeQuery, GQLAreaByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLAreaByCodeQuery, GQLAreaByCodeQueryVariables>(AreaByCodeDocument, options);
        }
export type AreaByCodeQueryHookResult = ReturnType<typeof useAreaByCodeQuery>;
export type AreaByCodeLazyQueryHookResult = ReturnType<typeof useAreaByCodeLazyQuery>;
export type AreaByCodeQueryResult = Apollo.QueryResult<GQLAreaByCodeQuery, GQLAreaByCodeQueryVariables>;
export const SigninDocument = gql`
    mutation signin($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
  }
}
    `;
export type GQLSigninMutationFn = Apollo.MutationFunction<GQLSigninMutation, GQLSigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<GQLSigninMutation, GQLSigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLSigninMutation, GQLSigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<GQLSigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<GQLSigninMutation, GQLSigninMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export type GQLChangePasswordMutationFn = Apollo.MutationFunction<GQLChangePasswordMutation, GQLChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<GQLChangePasswordMutation, GQLChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLChangePasswordMutation, GQLChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<GQLChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<GQLChangePasswordMutation, GQLChangePasswordMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
    `;
export type GQLRequestPasswordResetMutationFn = Apollo.MutationFunction<GQLRequestPasswordResetMutation, GQLRequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<GQLRequestPasswordResetMutation, GQLRequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLRequestPasswordResetMutation, GQLRequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<GQLRequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<GQLRequestPasswordResetMutation, GQLRequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type GQLResetPasswordMutationFn = Apollo.MutationFunction<GQLResetPasswordMutation, GQLResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<GQLResetPasswordMutation, GQLResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLResetPasswordMutation, GQLResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<GQLResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<GQLResetPasswordMutation, GQLResetPasswordMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($token: String!) {
  verifyEmail(token: $token)
}
    `;
export type GQLVerifyEmailMutationFn = Apollo.MutationFunction<GQLVerifyEmailMutation, GQLVerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<GQLVerifyEmailMutation, GQLVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLVerifyEmailMutation, GQLVerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<GQLVerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<GQLVerifyEmailMutation, GQLVerifyEmailMutationVariables>;
export const ForceVerifyEmailDocument = gql`
    mutation forceVerifyEmail($userId: ID!) {
  forceVerifyEmail(userId: $userId) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type GQLForceVerifyEmailMutationFn = Apollo.MutationFunction<GQLForceVerifyEmailMutation, GQLForceVerifyEmailMutationVariables>;

/**
 * __useForceVerifyEmailMutation__
 *
 * To run a mutation, you first call `useForceVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceVerifyEmailMutation, { data, loading, error }] = useForceVerifyEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useForceVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<GQLForceVerifyEmailMutation, GQLForceVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLForceVerifyEmailMutation, GQLForceVerifyEmailMutationVariables>(ForceVerifyEmailDocument, options);
      }
export type ForceVerifyEmailMutationHookResult = ReturnType<typeof useForceVerifyEmailMutation>;
export type ForceVerifyEmailMutationResult = Apollo.MutationResult<GQLForceVerifyEmailMutation>;
export type ForceVerifyEmailMutationOptions = Apollo.BaseMutationOptions<GQLForceVerifyEmailMutation, GQLForceVerifyEmailMutationVariables>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($paymentMethodId: String!) {
  addPaymentMethod(paymentMethodId: $paymentMethodId) {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;
export type GQLAddPaymentMethodMutationFn = Apollo.MutationFunction<GQLAddPaymentMethodMutation, GQLAddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddPaymentMethodMutation, GQLAddPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddPaymentMethodMutation, GQLAddPaymentMethodMutationVariables>(AddPaymentMethodDocument, options);
      }
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<GQLAddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<GQLAddPaymentMethodMutation, GQLAddPaymentMethodMutationVariables>;
export const PaymentMethodsDocument = gql`
    query paymentMethods {
  paymentMethods {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GQLPaymentMethodsQuery, GQLPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPaymentMethodsQuery, GQLPaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPaymentMethodsQuery, GQLPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPaymentMethodsQuery, GQLPaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<GQLPaymentMethodsQuery, GQLPaymentMethodsQueryVariables>;
export const GalleryImagesDocument = gql`
    query galleryImages($input: GalleryImagesInput!) {
  galleryImages(input: $input) {
    ...GalleryImage
  }
}
    ${GalleryImageFragmentDoc}`;

/**
 * __useGalleryImagesQuery__
 *
 * To run a query within a React component, call `useGalleryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryImagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGalleryImagesQuery(baseOptions: Apollo.QueryHookOptions<GQLGalleryImagesQuery, GQLGalleryImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLGalleryImagesQuery, GQLGalleryImagesQueryVariables>(GalleryImagesDocument, options);
      }
export function useGalleryImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLGalleryImagesQuery, GQLGalleryImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLGalleryImagesQuery, GQLGalleryImagesQueryVariables>(GalleryImagesDocument, options);
        }
export type GalleryImagesQueryHookResult = ReturnType<typeof useGalleryImagesQuery>;
export type GalleryImagesLazyQueryHookResult = ReturnType<typeof useGalleryImagesLazyQuery>;
export type GalleryImagesQueryResult = Apollo.QueryResult<GQLGalleryImagesQuery, GQLGalleryImagesQueryVariables>;
export const AddGalleryImageDocument = gql`
    mutation addGalleryImage($image: Upload!) {
  addGalleryImage(image: $image) {
    ...GalleryImage
  }
}
    ${GalleryImageFragmentDoc}`;
export type GQLAddGalleryImageMutationFn = Apollo.MutationFunction<GQLAddGalleryImageMutation, GQLAddGalleryImageMutationVariables>;

/**
 * __useAddGalleryImageMutation__
 *
 * To run a mutation, you first call `useAddGalleryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGalleryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGalleryImageMutation, { data, loading, error }] = useAddGalleryImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddGalleryImageMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddGalleryImageMutation, GQLAddGalleryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddGalleryImageMutation, GQLAddGalleryImageMutationVariables>(AddGalleryImageDocument, options);
      }
export type AddGalleryImageMutationHookResult = ReturnType<typeof useAddGalleryImageMutation>;
export type AddGalleryImageMutationResult = Apollo.MutationResult<GQLAddGalleryImageMutation>;
export type AddGalleryImageMutationOptions = Apollo.BaseMutationOptions<GQLAddGalleryImageMutation, GQLAddGalleryImageMutationVariables>;
export const DeleteGalleryImageDocument = gql`
    mutation deleteGalleryImage($input: DeleteGalleryImageInput!) {
  deleteGalleryImage(input: $input)
}
    `;
export type GQLDeleteGalleryImageMutationFn = Apollo.MutationFunction<GQLDeleteGalleryImageMutation, GQLDeleteGalleryImageMutationVariables>;

/**
 * __useDeleteGalleryImageMutation__
 *
 * To run a mutation, you first call `useDeleteGalleryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGalleryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGalleryImageMutation, { data, loading, error }] = useDeleteGalleryImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGalleryImageMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteGalleryImageMutation, GQLDeleteGalleryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteGalleryImageMutation, GQLDeleteGalleryImageMutationVariables>(DeleteGalleryImageDocument, options);
      }
export type DeleteGalleryImageMutationHookResult = ReturnType<typeof useDeleteGalleryImageMutation>;
export type DeleteGalleryImageMutationResult = Apollo.MutationResult<GQLDeleteGalleryImageMutation>;
export type DeleteGalleryImageMutationOptions = Apollo.BaseMutationOptions<GQLDeleteGalleryImageMutation, GQLDeleteGalleryImageMutationVariables>;
export const InquiryDocument = gql`
    query inquiry($input: InquiryInput!) {
  inquiry(input: $input) {
    ...Inquiry
  }
}
    ${InquiryFragmentDoc}`;

/**
 * __useInquiryQuery__
 *
 * To run a query within a React component, call `useInquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquiryQuery(baseOptions: Apollo.QueryHookOptions<GQLInquiryQuery, GQLInquiryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLInquiryQuery, GQLInquiryQueryVariables>(InquiryDocument, options);
      }
export function useInquiryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLInquiryQuery, GQLInquiryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLInquiryQuery, GQLInquiryQueryVariables>(InquiryDocument, options);
        }
export type InquiryQueryHookResult = ReturnType<typeof useInquiryQuery>;
export type InquiryLazyQueryHookResult = ReturnType<typeof useInquiryLazyQuery>;
export type InquiryQueryResult = Apollo.QueryResult<GQLInquiryQuery, GQLInquiryQueryVariables>;
export const InquiriesDocument = gql`
    query inquiries($input: InquiriesInput!) {
  inquiries(input: $input) {
    count
    items {
      ...Inquiry
    }
  }
}
    ${InquiryFragmentDoc}`;

/**
 * __useInquiriesQuery__
 *
 * To run a query within a React component, call `useInquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquiriesQuery(baseOptions: Apollo.QueryHookOptions<GQLInquiriesQuery, GQLInquiriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLInquiriesQuery, GQLInquiriesQueryVariables>(InquiriesDocument, options);
      }
export function useInquiriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLInquiriesQuery, GQLInquiriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLInquiriesQuery, GQLInquiriesQueryVariables>(InquiriesDocument, options);
        }
export type InquiriesQueryHookResult = ReturnType<typeof useInquiriesQuery>;
export type InquiriesLazyQueryHookResult = ReturnType<typeof useInquiriesLazyQuery>;
export type InquiriesQueryResult = Apollo.QueryResult<GQLInquiriesQuery, GQLInquiriesQueryVariables>;
export const CreateInquiryDocument = gql`
    mutation createInquiry($input: CreateInquiryInput!) {
  createInquiry(input: $input) {
    ...Inquiry
  }
}
    ${InquiryFragmentDoc}`;
export type GQLCreateInquiryMutationFn = Apollo.MutationFunction<GQLCreateInquiryMutation, GQLCreateInquiryMutationVariables>;

/**
 * __useCreateInquiryMutation__
 *
 * To run a mutation, you first call `useCreateInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInquiryMutation, { data, loading, error }] = useCreateInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInquiryMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateInquiryMutation, GQLCreateInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateInquiryMutation, GQLCreateInquiryMutationVariables>(CreateInquiryDocument, options);
      }
export type CreateInquiryMutationHookResult = ReturnType<typeof useCreateInquiryMutation>;
export type CreateInquiryMutationResult = Apollo.MutationResult<GQLCreateInquiryMutation>;
export type CreateInquiryMutationOptions = Apollo.BaseMutationOptions<GQLCreateInquiryMutation, GQLCreateInquiryMutationVariables>;
export const DeleteInquiryDocument = gql`
    mutation deleteInquiry($input: DeleteInquiryInput!) {
  deleteInquiry(input: $input)
}
    `;
export type GQLDeleteInquiryMutationFn = Apollo.MutationFunction<GQLDeleteInquiryMutation, GQLDeleteInquiryMutationVariables>;

/**
 * __useDeleteInquiryMutation__
 *
 * To run a mutation, you first call `useDeleteInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInquiryMutation, { data, loading, error }] = useDeleteInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInquiryMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteInquiryMutation, GQLDeleteInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteInquiryMutation, GQLDeleteInquiryMutationVariables>(DeleteInquiryDocument, options);
      }
export type DeleteInquiryMutationHookResult = ReturnType<typeof useDeleteInquiryMutation>;
export type DeleteInquiryMutationResult = Apollo.MutationResult<GQLDeleteInquiryMutation>;
export type DeleteInquiryMutationOptions = Apollo.BaseMutationOptions<GQLDeleteInquiryMutation, GQLDeleteInquiryMutationVariables>;
export const CreateJobDocument = gql`
    mutation createJob($job: JobInput!) {
  createJob(job: $job) {
    ...Job
  }
}
    ${JobFragmentDoc}`;
export type GQLCreateJobMutationFn = Apollo.MutationFunction<GQLCreateJobMutation, GQLCreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateJobMutation, GQLCreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateJobMutation, GQLCreateJobMutationVariables>(CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<GQLCreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<GQLCreateJobMutation, GQLCreateJobMutationVariables>;
export const JobDocument = gql`
    query job($jobId: ID!) {
  job(jobId: $jobId) {
    ...Job
    user {
      ...User
    }
    photographer {
      ...Photographer
      equipment {
        name
        screenName
      }
    }
  }
}
    ${JobFragmentDoc}
${UserFragmentDoc}
${PhotographerFragmentDoc}`;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobQuery(baseOptions: Apollo.QueryHookOptions<GQLJobQuery, GQLJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobQuery, GQLJobQueryVariables>(JobDocument, options);
      }
export function useJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobQuery, GQLJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobQuery, GQLJobQueryVariables>(JobDocument, options);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<GQLJobQuery, GQLJobQueryVariables>;
export const JobPaymentIntentDocument = gql`
    query jobPaymentIntent($jobId: ID!) {
  jobPaymentIntent(jobId: $jobId) {
    clientSecret
    amount
  }
}
    `;

/**
 * __useJobPaymentIntentQuery__
 *
 * To run a query within a React component, call `useJobPaymentIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPaymentIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPaymentIntentQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobPaymentIntentQuery(baseOptions: Apollo.QueryHookOptions<GQLJobPaymentIntentQuery, GQLJobPaymentIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobPaymentIntentQuery, GQLJobPaymentIntentQueryVariables>(JobPaymentIntentDocument, options);
      }
export function useJobPaymentIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobPaymentIntentQuery, GQLJobPaymentIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobPaymentIntentQuery, GQLJobPaymentIntentQueryVariables>(JobPaymentIntentDocument, options);
        }
export type JobPaymentIntentQueryHookResult = ReturnType<typeof useJobPaymentIntentQuery>;
export type JobPaymentIntentLazyQueryHookResult = ReturnType<typeof useJobPaymentIntentLazyQuery>;
export type JobPaymentIntentQueryResult = Apollo.QueryResult<GQLJobPaymentIntentQuery, GQLJobPaymentIntentQueryVariables>;
export const JobsDocument = gql`
    query jobs($limit: Int, $offset: Int, $query: String, $businessForm: BusinessForm, $categoryNames: [String!], $deadlineRange: Int, $maxFee: Int, $minFee: Int, $userNames: [String!], $photographerNames: [String!]) {
  jobs(
    limit: $limit
    offset: $offset
    query: $query
    businessForm: $businessForm
    categoryNames: $categoryNames
    deadlineRange: $deadlineRange
    maxFee: $maxFee
    minFee: $minFee
    userNames: $userNames
    photographerNames: $photographerNames
  ) {
    count
    items {
      ...Job
      user {
        ...User
      }
      photographer {
        ...Photographer
      }
    }
  }
}
    ${JobFragmentDoc}
${UserFragmentDoc}
${PhotographerFragmentDoc}`;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *      businessForm: // value for 'businessForm'
 *      categoryNames: // value for 'categoryNames'
 *      deadlineRange: // value for 'deadlineRange'
 *      maxFee: // value for 'maxFee'
 *      minFee: // value for 'minFee'
 *      userNames: // value for 'userNames'
 *      photographerNames: // value for 'photographerNames'
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<GQLJobsQuery, GQLJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobsQuery, GQLJobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobsQuery, GQLJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobsQuery, GQLJobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<GQLJobsQuery, GQLJobsQueryVariables>;
export const JobCommentsDocument = gql`
    query jobComments($limit: Int, $offset: Int, $jobId: ID!) {
  jobComments(limit: $limit, offset: $offset, jobId: $jobId) {
    count
    items {
      ...JobComment
    }
  }
}
    ${JobCommentFragmentDoc}`;

/**
 * __useJobCommentsQuery__
 *
 * To run a query within a React component, call `useJobCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobCommentsQuery(baseOptions: Apollo.QueryHookOptions<GQLJobCommentsQuery, GQLJobCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobCommentsQuery, GQLJobCommentsQueryVariables>(JobCommentsDocument, options);
      }
export function useJobCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobCommentsQuery, GQLJobCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobCommentsQuery, GQLJobCommentsQueryVariables>(JobCommentsDocument, options);
        }
export type JobCommentsQueryHookResult = ReturnType<typeof useJobCommentsQuery>;
export type JobCommentsLazyQueryHookResult = ReturnType<typeof useJobCommentsLazyQuery>;
export type JobCommentsQueryResult = Apollo.QueryResult<GQLJobCommentsQuery, GQLJobCommentsQueryVariables>;
export const EquipmentListDocument = gql`
    query equipmentList {
  equipmentList {
    name
    screenName
  }
}
    `;

/**
 * __useEquipmentListQuery__
 *
 * To run a query within a React component, call `useEquipmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEquipmentListQuery(baseOptions?: Apollo.QueryHookOptions<GQLEquipmentListQuery, GQLEquipmentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLEquipmentListQuery, GQLEquipmentListQueryVariables>(EquipmentListDocument, options);
      }
export function useEquipmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLEquipmentListQuery, GQLEquipmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLEquipmentListQuery, GQLEquipmentListQueryVariables>(EquipmentListDocument, options);
        }
export type EquipmentListQueryHookResult = ReturnType<typeof useEquipmentListQuery>;
export type EquipmentListLazyQueryHookResult = ReturnType<typeof useEquipmentListLazyQuery>;
export type EquipmentListQueryResult = Apollo.QueryResult<GQLEquipmentListQuery, GQLEquipmentListQueryVariables>;
export const JobCategoriesDocument = gql`
    query jobCategories {
  jobCategories {
    name
    screenName
  }
}
    `;

/**
 * __useJobCategoriesQuery__
 *
 * To run a query within a React component, call `useJobCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GQLJobCategoriesQuery, GQLJobCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobCategoriesQuery, GQLJobCategoriesQueryVariables>(JobCategoriesDocument, options);
      }
export function useJobCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobCategoriesQuery, GQLJobCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobCategoriesQuery, GQLJobCategoriesQueryVariables>(JobCategoriesDocument, options);
        }
export type JobCategoriesQueryHookResult = ReturnType<typeof useJobCategoriesQuery>;
export type JobCategoriesLazyQueryHookResult = ReturnType<typeof useJobCategoriesLazyQuery>;
export type JobCategoriesQueryResult = Apollo.QueryResult<GQLJobCategoriesQuery, GQLJobCategoriesQueryVariables>;
export const AddJobCommentDocument = gql`
    mutation addJobComment($jobCommentInput: JobCommentInput!) {
  addJobComment(jobComment: $jobCommentInput) {
    text
    createdAt
    updatedAt
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type GQLAddJobCommentMutationFn = Apollo.MutationFunction<GQLAddJobCommentMutation, GQLAddJobCommentMutationVariables>;

/**
 * __useAddJobCommentMutation__
 *
 * To run a mutation, you first call `useAddJobCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobCommentMutation, { data, loading, error }] = useAddJobCommentMutation({
 *   variables: {
 *      jobCommentInput: // value for 'jobCommentInput'
 *   },
 * });
 */
export function useAddJobCommentMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddJobCommentMutation, GQLAddJobCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddJobCommentMutation, GQLAddJobCommentMutationVariables>(AddJobCommentDocument, options);
      }
export type AddJobCommentMutationHookResult = ReturnType<typeof useAddJobCommentMutation>;
export type AddJobCommentMutationResult = Apollo.MutationResult<GQLAddJobCommentMutation>;
export type AddJobCommentMutationOptions = Apollo.BaseMutationOptions<GQLAddJobCommentMutation, GQLAddJobCommentMutationVariables>;
export const AddJobMessageDocument = gql`
    mutation addJobMessage($jobMessageInput: JobMessageInput!) {
  addJobMessage(jobMessage: $jobMessageInput) {
    text
    createdAt
    updatedAt
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type GQLAddJobMessageMutationFn = Apollo.MutationFunction<GQLAddJobMessageMutation, GQLAddJobMessageMutationVariables>;

/**
 * __useAddJobMessageMutation__
 *
 * To run a mutation, you first call `useAddJobMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobMessageMutation, { data, loading, error }] = useAddJobMessageMutation({
 *   variables: {
 *      jobMessageInput: // value for 'jobMessageInput'
 *   },
 * });
 */
export function useAddJobMessageMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddJobMessageMutation, GQLAddJobMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddJobMessageMutation, GQLAddJobMessageMutationVariables>(AddJobMessageDocument, options);
      }
export type AddJobMessageMutationHookResult = ReturnType<typeof useAddJobMessageMutation>;
export type AddJobMessageMutationResult = Apollo.MutationResult<GQLAddJobMessageMutation>;
export type AddJobMessageMutationOptions = Apollo.BaseMutationOptions<GQLAddJobMessageMutation, GQLAddJobMessageMutationVariables>;
export const JobMessagesDocument = gql`
    query jobMessages($jobId: ID!, $limit: Int, $offset: Int) {
  jobMessages(jobId: $jobId, limit: $limit, offset: $offset) {
    count
    items {
      id
      text
      createdAt
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useJobMessagesQuery__
 *
 * To run a query within a React component, call `useJobMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobMessagesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useJobMessagesQuery(baseOptions: Apollo.QueryHookOptions<GQLJobMessagesQuery, GQLJobMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobMessagesQuery, GQLJobMessagesQueryVariables>(JobMessagesDocument, options);
      }
export function useJobMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobMessagesQuery, GQLJobMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobMessagesQuery, GQLJobMessagesQueryVariables>(JobMessagesDocument, options);
        }
export type JobMessagesQueryHookResult = ReturnType<typeof useJobMessagesQuery>;
export type JobMessagesLazyQueryHookResult = ReturnType<typeof useJobMessagesLazyQuery>;
export type JobMessagesQueryResult = Apollo.QueryResult<GQLJobMessagesQuery, GQLJobMessagesQueryVariables>;
export const JobApplicationsDocument = gql`
    query jobApplications($jobId: ID!) {
  jobApplications(jobId: $jobId) {
    ...JobApplication
    user {
      ...Photographer
    }
  }
}
    ${JobApplicationFragmentDoc}
${PhotographerFragmentDoc}`;

/**
 * __useJobApplicationsQuery__
 *
 * To run a query within a React component, call `useJobApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobApplicationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobApplicationsQuery(baseOptions: Apollo.QueryHookOptions<GQLJobApplicationsQuery, GQLJobApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobApplicationsQuery, GQLJobApplicationsQueryVariables>(JobApplicationsDocument, options);
      }
export function useJobApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobApplicationsQuery, GQLJobApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobApplicationsQuery, GQLJobApplicationsQueryVariables>(JobApplicationsDocument, options);
        }
export type JobApplicationsQueryHookResult = ReturnType<typeof useJobApplicationsQuery>;
export type JobApplicationsLazyQueryHookResult = ReturnType<typeof useJobApplicationsLazyQuery>;
export type JobApplicationsQueryResult = Apollo.QueryResult<GQLJobApplicationsQuery, GQLJobApplicationsQueryVariables>;
export const JobApplicationsByUserNameDocument = gql`
    query jobApplicationsByUserName($userName: String!) {
  jobApplications(userName: $userName) {
    ...JobApplication
    job {
      ...Job
      user {
        ...User
      }
      photographer {
        ...Photographer
      }
    }
  }
}
    ${JobApplicationFragmentDoc}
${JobFragmentDoc}
${UserFragmentDoc}
${PhotographerFragmentDoc}`;

/**
 * __useJobApplicationsByUserNameQuery__
 *
 * To run a query within a React component, call `useJobApplicationsByUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobApplicationsByUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobApplicationsByUserNameQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useJobApplicationsByUserNameQuery(baseOptions: Apollo.QueryHookOptions<GQLJobApplicationsByUserNameQuery, GQLJobApplicationsByUserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLJobApplicationsByUserNameQuery, GQLJobApplicationsByUserNameQueryVariables>(JobApplicationsByUserNameDocument, options);
      }
export function useJobApplicationsByUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLJobApplicationsByUserNameQuery, GQLJobApplicationsByUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLJobApplicationsByUserNameQuery, GQLJobApplicationsByUserNameQueryVariables>(JobApplicationsByUserNameDocument, options);
        }
export type JobApplicationsByUserNameQueryHookResult = ReturnType<typeof useJobApplicationsByUserNameQuery>;
export type JobApplicationsByUserNameLazyQueryHookResult = ReturnType<typeof useJobApplicationsByUserNameLazyQuery>;
export type JobApplicationsByUserNameQueryResult = Apollo.QueryResult<GQLJobApplicationsByUserNameQuery, GQLJobApplicationsByUserNameQueryVariables>;
export const CancelApplyForJobDocument = gql`
    mutation cancelApplyForJob($jobId: ID!) {
  cancelApplyForJob(jobId: $jobId) {
    ...JobApplication
    job {
      ...Job
    }
  }
}
    ${JobApplicationFragmentDoc}
${JobFragmentDoc}`;
export type GQLCancelApplyForJobMutationFn = Apollo.MutationFunction<GQLCancelApplyForJobMutation, GQLCancelApplyForJobMutationVariables>;

/**
 * __useCancelApplyForJobMutation__
 *
 * To run a mutation, you first call `useCancelApplyForJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelApplyForJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelApplyForJobMutation, { data, loading, error }] = useCancelApplyForJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCancelApplyForJobMutation(baseOptions?: Apollo.MutationHookOptions<GQLCancelApplyForJobMutation, GQLCancelApplyForJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCancelApplyForJobMutation, GQLCancelApplyForJobMutationVariables>(CancelApplyForJobDocument, options);
      }
export type CancelApplyForJobMutationHookResult = ReturnType<typeof useCancelApplyForJobMutation>;
export type CancelApplyForJobMutationResult = Apollo.MutationResult<GQLCancelApplyForJobMutation>;
export type CancelApplyForJobMutationOptions = Apollo.BaseMutationOptions<GQLCancelApplyForJobMutation, GQLCancelApplyForJobMutationVariables>;
export const AcceptJobApplicationDocument = gql`
    mutation acceptJobApplication($jobId: ID!, $userId: ID!) {
  acceptJobApplication(jobId: $jobId, userId: $userId) {
    ...JobApplication
    job {
      ...Job
    }
  }
}
    ${JobApplicationFragmentDoc}
${JobFragmentDoc}`;
export type GQLAcceptJobApplicationMutationFn = Apollo.MutationFunction<GQLAcceptJobApplicationMutation, GQLAcceptJobApplicationMutationVariables>;

/**
 * __useAcceptJobApplicationMutation__
 *
 * To run a mutation, you first call `useAcceptJobApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptJobApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptJobApplicationMutation, { data, loading, error }] = useAcceptJobApplicationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAcceptJobApplicationMutation(baseOptions?: Apollo.MutationHookOptions<GQLAcceptJobApplicationMutation, GQLAcceptJobApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAcceptJobApplicationMutation, GQLAcceptJobApplicationMutationVariables>(AcceptJobApplicationDocument, options);
      }
export type AcceptJobApplicationMutationHookResult = ReturnType<typeof useAcceptJobApplicationMutation>;
export type AcceptJobApplicationMutationResult = Apollo.MutationResult<GQLAcceptJobApplicationMutation>;
export type AcceptJobApplicationMutationOptions = Apollo.BaseMutationOptions<GQLAcceptJobApplicationMutation, GQLAcceptJobApplicationMutationVariables>;
export const ApplyForJobDocument = gql`
    mutation applyForJob($jobId: ID!) {
  applyForJob(jobId: $jobId) {
    ...JobApplication
    job {
      ...Job
    }
    user {
      ...User
    }
  }
}
    ${JobApplicationFragmentDoc}
${JobFragmentDoc}
${UserFragmentDoc}`;
export type GQLApplyForJobMutationFn = Apollo.MutationFunction<GQLApplyForJobMutation, GQLApplyForJobMutationVariables>;

/**
 * __useApplyForJobMutation__
 *
 * To run a mutation, you first call `useApplyForJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForJobMutation, { data, loading, error }] = useApplyForJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useApplyForJobMutation(baseOptions?: Apollo.MutationHookOptions<GQLApplyForJobMutation, GQLApplyForJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLApplyForJobMutation, GQLApplyForJobMutationVariables>(ApplyForJobDocument, options);
      }
export type ApplyForJobMutationHookResult = ReturnType<typeof useApplyForJobMutation>;
export type ApplyForJobMutationResult = Apollo.MutationResult<GQLApplyForJobMutation>;
export type ApplyForJobMutationOptions = Apollo.BaseMutationOptions<GQLApplyForJobMutation, GQLApplyForJobMutationVariables>;
export const AddDeliverablePreviewDocument = gql`
    mutation addDeliverablePreview($jobId: ID!, $image: Upload!) {
  addDeliverablePreview(jobId: $jobId, image: $image) {
    ...DeliverablePreview
  }
}
    ${DeliverablePreviewFragmentDoc}`;
export type GQLAddDeliverablePreviewMutationFn = Apollo.MutationFunction<GQLAddDeliverablePreviewMutation, GQLAddDeliverablePreviewMutationVariables>;

/**
 * __useAddDeliverablePreviewMutation__
 *
 * To run a mutation, you first call `useAddDeliverablePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeliverablePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeliverablePreviewMutation, { data, loading, error }] = useAddDeliverablePreviewMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddDeliverablePreviewMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddDeliverablePreviewMutation, GQLAddDeliverablePreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddDeliverablePreviewMutation, GQLAddDeliverablePreviewMutationVariables>(AddDeliverablePreviewDocument, options);
      }
export type AddDeliverablePreviewMutationHookResult = ReturnType<typeof useAddDeliverablePreviewMutation>;
export type AddDeliverablePreviewMutationResult = Apollo.MutationResult<GQLAddDeliverablePreviewMutation>;
export type AddDeliverablePreviewMutationOptions = Apollo.BaseMutationOptions<GQLAddDeliverablePreviewMutation, GQLAddDeliverablePreviewMutationVariables>;
export const DeliverablePreviewsDocument = gql`
    query deliverablePreviews($jobId: ID!) {
  deliverablePreviews(jobId: $jobId) {
    ...DeliverablePreview
  }
}
    ${DeliverablePreviewFragmentDoc}`;

/**
 * __useDeliverablePreviewsQuery__
 *
 * To run a query within a React component, call `useDeliverablePreviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverablePreviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverablePreviewsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeliverablePreviewsQuery(baseOptions: Apollo.QueryHookOptions<GQLDeliverablePreviewsQuery, GQLDeliverablePreviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLDeliverablePreviewsQuery, GQLDeliverablePreviewsQueryVariables>(DeliverablePreviewsDocument, options);
      }
export function useDeliverablePreviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLDeliverablePreviewsQuery, GQLDeliverablePreviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLDeliverablePreviewsQuery, GQLDeliverablePreviewsQueryVariables>(DeliverablePreviewsDocument, options);
        }
export type DeliverablePreviewsQueryHookResult = ReturnType<typeof useDeliverablePreviewsQuery>;
export type DeliverablePreviewsLazyQueryHookResult = ReturnType<typeof useDeliverablePreviewsLazyQuery>;
export type DeliverablePreviewsQueryResult = Apollo.QueryResult<GQLDeliverablePreviewsQuery, GQLDeliverablePreviewsQueryVariables>;
export const AcceptDeliverablePreviewsDocument = gql`
    mutation acceptDeliverablePreviews($jobId: ID!) {
  acceptDeliverablePreviews(jobId: $jobId) {
    ...Job
  }
}
    ${JobFragmentDoc}`;
export type GQLAcceptDeliverablePreviewsMutationFn = Apollo.MutationFunction<GQLAcceptDeliverablePreviewsMutation, GQLAcceptDeliverablePreviewsMutationVariables>;

/**
 * __useAcceptDeliverablePreviewsMutation__
 *
 * To run a mutation, you first call `useAcceptDeliverablePreviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDeliverablePreviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDeliverablePreviewsMutation, { data, loading, error }] = useAcceptDeliverablePreviewsMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useAcceptDeliverablePreviewsMutation(baseOptions?: Apollo.MutationHookOptions<GQLAcceptDeliverablePreviewsMutation, GQLAcceptDeliverablePreviewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAcceptDeliverablePreviewsMutation, GQLAcceptDeliverablePreviewsMutationVariables>(AcceptDeliverablePreviewsDocument, options);
      }
export type AcceptDeliverablePreviewsMutationHookResult = ReturnType<typeof useAcceptDeliverablePreviewsMutation>;
export type AcceptDeliverablePreviewsMutationResult = Apollo.MutationResult<GQLAcceptDeliverablePreviewsMutation>;
export type AcceptDeliverablePreviewsMutationOptions = Apollo.BaseMutationOptions<GQLAcceptDeliverablePreviewsMutation, GQLAcceptDeliverablePreviewsMutationVariables>;
export const AddDeliverableDocument = gql`
    mutation addDeliverable($jobId: ID!, $data: Upload!) {
  addDeliverable(jobId: $jobId, data: $data) {
    ...Deliverable
  }
}
    ${DeliverableFragmentDoc}`;
export type GQLAddDeliverableMutationFn = Apollo.MutationFunction<GQLAddDeliverableMutation, GQLAddDeliverableMutationVariables>;

/**
 * __useAddDeliverableMutation__
 *
 * To run a mutation, you first call `useAddDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeliverableMutation, { data, loading, error }] = useAddDeliverableMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddDeliverableMutation, GQLAddDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddDeliverableMutation, GQLAddDeliverableMutationVariables>(AddDeliverableDocument, options);
      }
export type AddDeliverableMutationHookResult = ReturnType<typeof useAddDeliverableMutation>;
export type AddDeliverableMutationResult = Apollo.MutationResult<GQLAddDeliverableMutation>;
export type AddDeliverableMutationOptions = Apollo.BaseMutationOptions<GQLAddDeliverableMutation, GQLAddDeliverableMutationVariables>;
export const DeliverablesDocument = gql`
    query deliverables($jobId: ID!) {
  deliverables(jobId: $jobId) {
    ...Deliverable
  }
}
    ${DeliverableFragmentDoc}`;

/**
 * __useDeliverablesQuery__
 *
 * To run a query within a React component, call `useDeliverablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverablesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeliverablesQuery(baseOptions: Apollo.QueryHookOptions<GQLDeliverablesQuery, GQLDeliverablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLDeliverablesQuery, GQLDeliverablesQueryVariables>(DeliverablesDocument, options);
      }
export function useDeliverablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLDeliverablesQuery, GQLDeliverablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLDeliverablesQuery, GQLDeliverablesQueryVariables>(DeliverablesDocument, options);
        }
export type DeliverablesQueryHookResult = ReturnType<typeof useDeliverablesQuery>;
export type DeliverablesLazyQueryHookResult = ReturnType<typeof useDeliverablesLazyQuery>;
export type DeliverablesQueryResult = Apollo.QueryResult<GQLDeliverablesQuery, GQLDeliverablesQueryVariables>;
export const AllDeliverablesDocument = gql`
    query allDeliverables($limit: Int!, $offset: Int!) {
  allDeliverables(limit: $limit, offset: $offset) {
    count
    items {
      ...Deliverable
    }
  }
}
    ${DeliverableFragmentDoc}`;

/**
 * __useAllDeliverablesQuery__
 *
 * To run a query within a React component, call `useAllDeliverablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDeliverablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDeliverablesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllDeliverablesQuery(baseOptions: Apollo.QueryHookOptions<GQLAllDeliverablesQuery, GQLAllDeliverablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLAllDeliverablesQuery, GQLAllDeliverablesQueryVariables>(AllDeliverablesDocument, options);
      }
export function useAllDeliverablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLAllDeliverablesQuery, GQLAllDeliverablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLAllDeliverablesQuery, GQLAllDeliverablesQueryVariables>(AllDeliverablesDocument, options);
        }
export type AllDeliverablesQueryHookResult = ReturnType<typeof useAllDeliverablesQuery>;
export type AllDeliverablesLazyQueryHookResult = ReturnType<typeof useAllDeliverablesLazyQuery>;
export type AllDeliverablesQueryResult = Apollo.QueryResult<GQLAllDeliverablesQuery, GQLAllDeliverablesQueryVariables>;
export const DeleteDeliverableDocument = gql`
    mutation deleteDeliverable($deliverableId: ID!) {
  deleteDeliverable(deliverableId: $deliverableId) {
    ...Deliverable
  }
}
    ${DeliverableFragmentDoc}`;
export type GQLDeleteDeliverableMutationFn = Apollo.MutationFunction<GQLDeleteDeliverableMutation, GQLDeleteDeliverableMutationVariables>;

/**
 * __useDeleteDeliverableMutation__
 *
 * To run a mutation, you first call `useDeleteDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliverableMutation, { data, loading, error }] = useDeleteDeliverableMutation({
 *   variables: {
 *      deliverableId: // value for 'deliverableId'
 *   },
 * });
 */
export function useDeleteDeliverableMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteDeliverableMutation, GQLDeleteDeliverableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteDeliverableMutation, GQLDeleteDeliverableMutationVariables>(DeleteDeliverableDocument, options);
      }
export type DeleteDeliverableMutationHookResult = ReturnType<typeof useDeleteDeliverableMutation>;
export type DeleteDeliverableMutationResult = Apollo.MutationResult<GQLDeleteDeliverableMutation>;
export type DeleteDeliverableMutationOptions = Apollo.BaseMutationOptions<GQLDeleteDeliverableMutation, GQLDeleteDeliverableMutationVariables>;
export const NearJobsDocument = gql`
    query nearJobs($lat: Float!, $lng: Float!) {
  nearJobs(lat: $lat, lng: $lng) {
    ...Job
  }
}
    ${JobFragmentDoc}`;

/**
 * __useNearJobsQuery__
 *
 * To run a query within a React component, call `useNearJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNearJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNearJobsQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useNearJobsQuery(baseOptions: Apollo.QueryHookOptions<GQLNearJobsQuery, GQLNearJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLNearJobsQuery, GQLNearJobsQueryVariables>(NearJobsDocument, options);
      }
export function useNearJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLNearJobsQuery, GQLNearJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLNearJobsQuery, GQLNearJobsQueryVariables>(NearJobsDocument, options);
        }
export type NearJobsQueryHookResult = ReturnType<typeof useNearJobsQuery>;
export type NearJobsLazyQueryHookResult = ReturnType<typeof useNearJobsLazyQuery>;
export type NearJobsQueryResult = Apollo.QueryResult<GQLNearJobsQuery, GQLNearJobsQueryVariables>;
export const NewsPostDocument = gql`
    query newsPost($newsPostId: ID!) {
  newsPost(newsPostId: $newsPostId) {
    ...NewsPost
  }
}
    ${NewsPostFragmentDoc}`;

/**
 * __useNewsPostQuery__
 *
 * To run a query within a React component, call `useNewsPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostQuery({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useNewsPostQuery(baseOptions: Apollo.QueryHookOptions<GQLNewsPostQuery, GQLNewsPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLNewsPostQuery, GQLNewsPostQueryVariables>(NewsPostDocument, options);
      }
export function useNewsPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLNewsPostQuery, GQLNewsPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLNewsPostQuery, GQLNewsPostQueryVariables>(NewsPostDocument, options);
        }
export type NewsPostQueryHookResult = ReturnType<typeof useNewsPostQuery>;
export type NewsPostLazyQueryHookResult = ReturnType<typeof useNewsPostLazyQuery>;
export type NewsPostQueryResult = Apollo.QueryResult<GQLNewsPostQuery, GQLNewsPostQueryVariables>;
export const NewsPostsDocument = gql`
    query newsPosts($limit: Int!, $offset: Int!) {
  newsPosts(limit: $limit, offset: $offset) {
    count
    items {
      ...NewsPost
    }
  }
}
    ${NewsPostFragmentDoc}`;

/**
 * __useNewsPostsQuery__
 *
 * To run a query within a React component, call `useNewsPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNewsPostsQuery(baseOptions: Apollo.QueryHookOptions<GQLNewsPostsQuery, GQLNewsPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLNewsPostsQuery, GQLNewsPostsQueryVariables>(NewsPostsDocument, options);
      }
export function useNewsPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLNewsPostsQuery, GQLNewsPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLNewsPostsQuery, GQLNewsPostsQueryVariables>(NewsPostsDocument, options);
        }
export type NewsPostsQueryHookResult = ReturnType<typeof useNewsPostsQuery>;
export type NewsPostsLazyQueryHookResult = ReturnType<typeof useNewsPostsLazyQuery>;
export type NewsPostsQueryResult = Apollo.QueryResult<GQLNewsPostsQuery, GQLNewsPostsQueryVariables>;
export const AddNewsPostDocument = gql`
    mutation addNewsPost($addNewsPostInput: AddNewsPostInput!) {
  addNewsPost(addNewsPostInput: $addNewsPostInput) {
    ...NewsPost
  }
}
    ${NewsPostFragmentDoc}`;
export type GQLAddNewsPostMutationFn = Apollo.MutationFunction<GQLAddNewsPostMutation, GQLAddNewsPostMutationVariables>;

/**
 * __useAddNewsPostMutation__
 *
 * To run a mutation, you first call `useAddNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewsPostMutation, { data, loading, error }] = useAddNewsPostMutation({
 *   variables: {
 *      addNewsPostInput: // value for 'addNewsPostInput'
 *   },
 * });
 */
export function useAddNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddNewsPostMutation, GQLAddNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddNewsPostMutation, GQLAddNewsPostMutationVariables>(AddNewsPostDocument, options);
      }
export type AddNewsPostMutationHookResult = ReturnType<typeof useAddNewsPostMutation>;
export type AddNewsPostMutationResult = Apollo.MutationResult<GQLAddNewsPostMutation>;
export type AddNewsPostMutationOptions = Apollo.BaseMutationOptions<GQLAddNewsPostMutation, GQLAddNewsPostMutationVariables>;
export const DeleteNewsPostDocument = gql`
    mutation deleteNewsPost($newsPostId: ID!) {
  deleteNewsPost(newsPostId: $newsPostId)
}
    `;
export type GQLDeleteNewsPostMutationFn = Apollo.MutationFunction<GQLDeleteNewsPostMutation, GQLDeleteNewsPostMutationVariables>;

/**
 * __useDeleteNewsPostMutation__
 *
 * To run a mutation, you first call `useDeleteNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsPostMutation, { data, loading, error }] = useDeleteNewsPostMutation({
 *   variables: {
 *      newsPostId: // value for 'newsPostId'
 *   },
 * });
 */
export function useDeleteNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteNewsPostMutation, GQLDeleteNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteNewsPostMutation, GQLDeleteNewsPostMutationVariables>(DeleteNewsPostDocument, options);
      }
export type DeleteNewsPostMutationHookResult = ReturnType<typeof useDeleteNewsPostMutation>;
export type DeleteNewsPostMutationResult = Apollo.MutationResult<GQLDeleteNewsPostMutation>;
export type DeleteNewsPostMutationOptions = Apollo.BaseMutationOptions<GQLDeleteNewsPostMutation, GQLDeleteNewsPostMutationVariables>;
export const PaymentRequestDocument = gql`
    query paymentRequest($input: PaymentRequestInput!) {
  paymentRequest(input: $input) {
    ...PaymentRequest
    user {
      ...User
    }
  }
}
    ${PaymentRequestFragmentDoc}
${UserFragmentDoc}`;

/**
 * __usePaymentRequestQuery__
 *
 * To run a query within a React component, call `usePaymentRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentRequestQuery(baseOptions: Apollo.QueryHookOptions<GQLPaymentRequestQuery, GQLPaymentRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPaymentRequestQuery, GQLPaymentRequestQueryVariables>(PaymentRequestDocument, options);
      }
export function usePaymentRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPaymentRequestQuery, GQLPaymentRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPaymentRequestQuery, GQLPaymentRequestQueryVariables>(PaymentRequestDocument, options);
        }
export type PaymentRequestQueryHookResult = ReturnType<typeof usePaymentRequestQuery>;
export type PaymentRequestLazyQueryHookResult = ReturnType<typeof usePaymentRequestLazyQuery>;
export type PaymentRequestQueryResult = Apollo.QueryResult<GQLPaymentRequestQuery, GQLPaymentRequestQueryVariables>;
export const PaymentRequestsDocument = gql`
    query paymentRequests($input: PaymentRequestsInput!) {
  paymentRequests(input: $input) {
    count
    items {
      ...PaymentRequest
      user {
        ...User
      }
    }
  }
}
    ${PaymentRequestFragmentDoc}
${UserFragmentDoc}`;

/**
 * __usePaymentRequestsQuery__
 *
 * To run a query within a React component, call `usePaymentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentRequestsQuery(baseOptions: Apollo.QueryHookOptions<GQLPaymentRequestsQuery, GQLPaymentRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPaymentRequestsQuery, GQLPaymentRequestsQueryVariables>(PaymentRequestsDocument, options);
      }
export function usePaymentRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPaymentRequestsQuery, GQLPaymentRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPaymentRequestsQuery, GQLPaymentRequestsQueryVariables>(PaymentRequestsDocument, options);
        }
export type PaymentRequestsQueryHookResult = ReturnType<typeof usePaymentRequestsQuery>;
export type PaymentRequestsLazyQueryHookResult = ReturnType<typeof usePaymentRequestsLazyQuery>;
export type PaymentRequestsQueryResult = Apollo.QueryResult<GQLPaymentRequestsQuery, GQLPaymentRequestsQueryVariables>;
export const CreatePaymentRequestDocument = gql`
    mutation createPaymentRequest($input: CreatePaymentRequestInput!) {
  createPaymentRequest(input: $input) {
    ...PaymentRequest
  }
}
    ${PaymentRequestFragmentDoc}`;
export type GQLCreatePaymentRequestMutationFn = Apollo.MutationFunction<GQLCreatePaymentRequestMutation, GQLCreatePaymentRequestMutationVariables>;

/**
 * __useCreatePaymentRequestMutation__
 *
 * To run a mutation, you first call `useCreatePaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentRequestMutation, { data, loading, error }] = useCreatePaymentRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentRequestMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreatePaymentRequestMutation, GQLCreatePaymentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreatePaymentRequestMutation, GQLCreatePaymentRequestMutationVariables>(CreatePaymentRequestDocument, options);
      }
export type CreatePaymentRequestMutationHookResult = ReturnType<typeof useCreatePaymentRequestMutation>;
export type CreatePaymentRequestMutationResult = Apollo.MutationResult<GQLCreatePaymentRequestMutation>;
export type CreatePaymentRequestMutationOptions = Apollo.BaseMutationOptions<GQLCreatePaymentRequestMutation, GQLCreatePaymentRequestMutationVariables>;
export const DeletePaymentRequestDocument = gql`
    mutation deletePaymentRequest($input: DeletePaymentRequestInput!) {
  deletePaymentRequest(input: $input)
}
    `;
export type GQLDeletePaymentRequestMutationFn = Apollo.MutationFunction<GQLDeletePaymentRequestMutation, GQLDeletePaymentRequestMutationVariables>;

/**
 * __useDeletePaymentRequestMutation__
 *
 * To run a mutation, you first call `useDeletePaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentRequestMutation, { data, loading, error }] = useDeletePaymentRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentRequestMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeletePaymentRequestMutation, GQLDeletePaymentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeletePaymentRequestMutation, GQLDeletePaymentRequestMutationVariables>(DeletePaymentRequestDocument, options);
      }
export type DeletePaymentRequestMutationHookResult = ReturnType<typeof useDeletePaymentRequestMutation>;
export type DeletePaymentRequestMutationResult = Apollo.MutationResult<GQLDeletePaymentRequestMutation>;
export type DeletePaymentRequestMutationOptions = Apollo.BaseMutationOptions<GQLDeletePaymentRequestMutation, GQLDeletePaymentRequestMutationVariables>;
export const PhotographicCategoriesDocument = gql`
    query photographicCategories {
  photographicCategories {
    ...PhotographicCategory
  }
}
    ${PhotographicCategoryFragmentDoc}`;

/**
 * __usePhotographicCategoriesQuery__
 *
 * To run a query within a React component, call `usePhotographicCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotographicCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotographicCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhotographicCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GQLPhotographicCategoriesQuery, GQLPhotographicCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPhotographicCategoriesQuery, GQLPhotographicCategoriesQueryVariables>(PhotographicCategoriesDocument, options);
      }
export function usePhotographicCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPhotographicCategoriesQuery, GQLPhotographicCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPhotographicCategoriesQuery, GQLPhotographicCategoriesQueryVariables>(PhotographicCategoriesDocument, options);
        }
export type PhotographicCategoriesQueryHookResult = ReturnType<typeof usePhotographicCategoriesQuery>;
export type PhotographicCategoriesLazyQueryHookResult = ReturnType<typeof usePhotographicCategoriesLazyQuery>;
export type PhotographicCategoriesQueryResult = Apollo.QueryResult<GQLPhotographicCategoriesQuery, GQLPhotographicCategoriesQueryVariables>;
export const AddPhotographicCategoryDocument = gql`
    mutation addPhotographicCategory($input: AddPhotographicCategoryInput!) {
  addPhotographicCategory(input: $input) {
    ...PhotographicCategory
  }
}
    ${PhotographicCategoryFragmentDoc}`;
export type GQLAddPhotographicCategoryMutationFn = Apollo.MutationFunction<GQLAddPhotographicCategoryMutation, GQLAddPhotographicCategoryMutationVariables>;

/**
 * __useAddPhotographicCategoryMutation__
 *
 * To run a mutation, you first call `useAddPhotographicCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhotographicCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhotographicCategoryMutation, { data, loading, error }] = useAddPhotographicCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPhotographicCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddPhotographicCategoryMutation, GQLAddPhotographicCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddPhotographicCategoryMutation, GQLAddPhotographicCategoryMutationVariables>(AddPhotographicCategoryDocument, options);
      }
export type AddPhotographicCategoryMutationHookResult = ReturnType<typeof useAddPhotographicCategoryMutation>;
export type AddPhotographicCategoryMutationResult = Apollo.MutationResult<GQLAddPhotographicCategoryMutation>;
export type AddPhotographicCategoryMutationOptions = Apollo.BaseMutationOptions<GQLAddPhotographicCategoryMutation, GQLAddPhotographicCategoryMutationVariables>;
export const DeletePhotographicCategoryDocument = gql`
    mutation deletePhotographicCategory($input: DeletePhotographicCategoryInput!) {
  deletePhotographicCategory(input: $input)
}
    `;
export type GQLDeletePhotographicCategoryMutationFn = Apollo.MutationFunction<GQLDeletePhotographicCategoryMutation, GQLDeletePhotographicCategoryMutationVariables>;

/**
 * __useDeletePhotographicCategoryMutation__
 *
 * To run a mutation, you first call `useDeletePhotographicCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotographicCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotographicCategoryMutation, { data, loading, error }] = useDeletePhotographicCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePhotographicCategoryMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeletePhotographicCategoryMutation, GQLDeletePhotographicCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeletePhotographicCategoryMutation, GQLDeletePhotographicCategoryMutationVariables>(DeletePhotographicCategoryDocument, options);
      }
export type DeletePhotographicCategoryMutationHookResult = ReturnType<typeof useDeletePhotographicCategoryMutation>;
export type DeletePhotographicCategoryMutationResult = Apollo.MutationResult<GQLDeletePhotographicCategoryMutation>;
export type DeletePhotographicCategoryMutationOptions = Apollo.BaseMutationOptions<GQLDeletePhotographicCategoryMutation, GQLDeletePhotographicCategoryMutationVariables>;
export const PhotographersDocument = gql`
    query photographers($limit: Int, $offset: Int, $equipment: [String!], $photographicCategories: [String!], $query: String) {
  photographers(
    limit: $limit
    offset: $offset
    equipmentNames: $equipment
    photographicCategoryNames: $photographicCategories
    query: $query
  ) {
    count
    items {
      ...Photographer
    }
  }
}
    ${PhotographerFragmentDoc}`;

/**
 * __usePhotographersQuery__
 *
 * To run a query within a React component, call `usePhotographersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotographersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotographersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      equipment: // value for 'equipment'
 *      photographicCategories: // value for 'photographicCategories'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePhotographersQuery(baseOptions?: Apollo.QueryHookOptions<GQLPhotographersQuery, GQLPhotographersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPhotographersQuery, GQLPhotographersQueryVariables>(PhotographersDocument, options);
      }
export function usePhotographersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPhotographersQuery, GQLPhotographersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPhotographersQuery, GQLPhotographersQueryVariables>(PhotographersDocument, options);
        }
export type PhotographersQueryHookResult = ReturnType<typeof usePhotographersQuery>;
export type PhotographersLazyQueryHookResult = ReturnType<typeof usePhotographersLazyQuery>;
export type PhotographersQueryResult = Apollo.QueryResult<GQLPhotographersQuery, GQLPhotographersQueryVariables>;
export const PhotographerDocument = gql`
    query photographer($userName: String!) {
  user(userName: $userName) {
    ...Photographer
  }
}
    ${PhotographerFragmentDoc}`;

/**
 * __usePhotographerQuery__
 *
 * To run a query within a React component, call `usePhotographerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotographerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotographerQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function usePhotographerQuery(baseOptions: Apollo.QueryHookOptions<GQLPhotographerQuery, GQLPhotographerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPhotographerQuery, GQLPhotographerQueryVariables>(PhotographerDocument, options);
      }
export function usePhotographerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPhotographerQuery, GQLPhotographerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPhotographerQuery, GQLPhotographerQueryVariables>(PhotographerDocument, options);
        }
export type PhotographerQueryHookResult = ReturnType<typeof usePhotographerQuery>;
export type PhotographerLazyQueryHookResult = ReturnType<typeof usePhotographerLazyQuery>;
export type PhotographerQueryResult = Apollo.QueryResult<GQLPhotographerQuery, GQLPhotographerQueryVariables>;
export const PhotographerCommentsDocument = gql`
    query photographerComments($limit: Int, $offset: Int, $photographerId: ID!) {
  photographerComments(
    limit: $limit
    offset: $offset
    photographerId: $photographerId
  ) {
    count
    items {
      ...PhotographerComment
    }
  }
}
    ${PhotographerCommentFragmentDoc}`;

/**
 * __usePhotographerCommentsQuery__
 *
 * To run a query within a React component, call `usePhotographerCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotographerCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotographerCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      photographerId: // value for 'photographerId'
 *   },
 * });
 */
export function usePhotographerCommentsQuery(baseOptions: Apollo.QueryHookOptions<GQLPhotographerCommentsQuery, GQLPhotographerCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLPhotographerCommentsQuery, GQLPhotographerCommentsQueryVariables>(PhotographerCommentsDocument, options);
      }
export function usePhotographerCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLPhotographerCommentsQuery, GQLPhotographerCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLPhotographerCommentsQuery, GQLPhotographerCommentsQueryVariables>(PhotographerCommentsDocument, options);
        }
export type PhotographerCommentsQueryHookResult = ReturnType<typeof usePhotographerCommentsQuery>;
export type PhotographerCommentsLazyQueryHookResult = ReturnType<typeof usePhotographerCommentsLazyQuery>;
export type PhotographerCommentsQueryResult = Apollo.QueryResult<GQLPhotographerCommentsQuery, GQLPhotographerCommentsQueryVariables>;
export const UpdatePhotographerProfileDocument = gql`
    mutation updatePhotographerProfile($photographerProfile: PhotographerProfileInput!) {
  updatePhotographerProfile(photographerProfile: $photographerProfile) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export type GQLUpdatePhotographerProfileMutationFn = Apollo.MutationFunction<GQLUpdatePhotographerProfileMutation, GQLUpdatePhotographerProfileMutationVariables>;

/**
 * __useUpdatePhotographerProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePhotographerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotographerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotographerProfileMutation, { data, loading, error }] = useUpdatePhotographerProfileMutation({
 *   variables: {
 *      photographerProfile: // value for 'photographerProfile'
 *   },
 * });
 */
export function useUpdatePhotographerProfileMutation(baseOptions?: Apollo.MutationHookOptions<GQLUpdatePhotographerProfileMutation, GQLUpdatePhotographerProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLUpdatePhotographerProfileMutation, GQLUpdatePhotographerProfileMutationVariables>(UpdatePhotographerProfileDocument, options);
      }
export type UpdatePhotographerProfileMutationHookResult = ReturnType<typeof useUpdatePhotographerProfileMutation>;
export type UpdatePhotographerProfileMutationResult = Apollo.MutationResult<GQLUpdatePhotographerProfileMutation>;
export type UpdatePhotographerProfileMutationOptions = Apollo.BaseMutationOptions<GQLUpdatePhotographerProfileMutation, GQLUpdatePhotographerProfileMutationVariables>;
export const AddPhotographerCommentDocument = gql`
    mutation addPhotographerComment($photographerCommentInput: PhotographerCommentInput!) {
  addPhotographerComment(photographerComment: $photographerCommentInput) {
    text
    createdAt
    updatedAt
    photographer {
      ...Photographer
    }
    photographerId
    user {
      ...User
    }
    userId
  }
}
    ${PhotographerFragmentDoc}
${UserFragmentDoc}`;
export type GQLAddPhotographerCommentMutationFn = Apollo.MutationFunction<GQLAddPhotographerCommentMutation, GQLAddPhotographerCommentMutationVariables>;

/**
 * __useAddPhotographerCommentMutation__
 *
 * To run a mutation, you first call `useAddPhotographerCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhotographerCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhotographerCommentMutation, { data, loading, error }] = useAddPhotographerCommentMutation({
 *   variables: {
 *      photographerCommentInput: // value for 'photographerCommentInput'
 *   },
 * });
 */
export function useAddPhotographerCommentMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddPhotographerCommentMutation, GQLAddPhotographerCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddPhotographerCommentMutation, GQLAddPhotographerCommentMutationVariables>(AddPhotographerCommentDocument, options);
      }
export type AddPhotographerCommentMutationHookResult = ReturnType<typeof useAddPhotographerCommentMutation>;
export type AddPhotographerCommentMutationResult = Apollo.MutationResult<GQLAddPhotographerCommentMutation>;
export type AddPhotographerCommentMutationOptions = Apollo.BaseMutationOptions<GQLAddPhotographerCommentMutation, GQLAddPhotographerCommentMutationVariables>;
export const StudioEntryDocument = gql`
    query studioEntry($input: StudioEntryInput!) {
  studioEntry(input: $input) {
    ...StudioEntry
  }
}
    ${StudioEntryFragmentDoc}`;

/**
 * __useStudioEntryQuery__
 *
 * To run a query within a React component, call `useStudioEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudioEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudioEntryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudioEntryQuery(baseOptions: Apollo.QueryHookOptions<GQLStudioEntryQuery, GQLStudioEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLStudioEntryQuery, GQLStudioEntryQueryVariables>(StudioEntryDocument, options);
      }
export function useStudioEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLStudioEntryQuery, GQLStudioEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLStudioEntryQuery, GQLStudioEntryQueryVariables>(StudioEntryDocument, options);
        }
export type StudioEntryQueryHookResult = ReturnType<typeof useStudioEntryQuery>;
export type StudioEntryLazyQueryHookResult = ReturnType<typeof useStudioEntryLazyQuery>;
export type StudioEntryQueryResult = Apollo.QueryResult<GQLStudioEntryQuery, GQLStudioEntryQueryVariables>;
export const StudioEntriesDocument = gql`
    query studioEntries($input: StudioEntriesInput!) {
  studioEntries(input: $input) {
    count
    items {
      ...StudioEntry
    }
  }
}
    ${StudioEntryFragmentDoc}`;

/**
 * __useStudioEntriesQuery__
 *
 * To run a query within a React component, call `useStudioEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudioEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudioEntriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudioEntriesQuery(baseOptions: Apollo.QueryHookOptions<GQLStudioEntriesQuery, GQLStudioEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLStudioEntriesQuery, GQLStudioEntriesQueryVariables>(StudioEntriesDocument, options);
      }
export function useStudioEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLStudioEntriesQuery, GQLStudioEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLStudioEntriesQuery, GQLStudioEntriesQueryVariables>(StudioEntriesDocument, options);
        }
export type StudioEntriesQueryHookResult = ReturnType<typeof useStudioEntriesQuery>;
export type StudioEntriesLazyQueryHookResult = ReturnType<typeof useStudioEntriesLazyQuery>;
export type StudioEntriesQueryResult = Apollo.QueryResult<GQLStudioEntriesQuery, GQLStudioEntriesQueryVariables>;
export const CreateStudioEntryDocument = gql`
    mutation createStudioEntry($input: CreateStudioEntryInput!) {
  createStudioEntry(input: $input) {
    ...StudioEntry
  }
}
    ${StudioEntryFragmentDoc}`;
export type GQLCreateStudioEntryMutationFn = Apollo.MutationFunction<GQLCreateStudioEntryMutation, GQLCreateStudioEntryMutationVariables>;

/**
 * __useCreateStudioEntryMutation__
 *
 * To run a mutation, you first call `useCreateStudioEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudioEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudioEntryMutation, { data, loading, error }] = useCreateStudioEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudioEntryMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateStudioEntryMutation, GQLCreateStudioEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateStudioEntryMutation, GQLCreateStudioEntryMutationVariables>(CreateStudioEntryDocument, options);
      }
export type CreateStudioEntryMutationHookResult = ReturnType<typeof useCreateStudioEntryMutation>;
export type CreateStudioEntryMutationResult = Apollo.MutationResult<GQLCreateStudioEntryMutation>;
export type CreateStudioEntryMutationOptions = Apollo.BaseMutationOptions<GQLCreateStudioEntryMutation, GQLCreateStudioEntryMutationVariables>;
export const DeleteStudioEntryDocument = gql`
    mutation deleteStudioEntry($input: DeleteStudioEntryInput!) {
  deleteStudioEntry(input: $input)
}
    `;
export type GQLDeleteStudioEntryMutationFn = Apollo.MutationFunction<GQLDeleteStudioEntryMutation, GQLDeleteStudioEntryMutationVariables>;

/**
 * __useDeleteStudioEntryMutation__
 *
 * To run a mutation, you first call `useDeleteStudioEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudioEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudioEntryMutation, { data, loading, error }] = useDeleteStudioEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStudioEntryMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteStudioEntryMutation, GQLDeleteStudioEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteStudioEntryMutation, GQLDeleteStudioEntryMutationVariables>(DeleteStudioEntryDocument, options);
      }
export type DeleteStudioEntryMutationHookResult = ReturnType<typeof useDeleteStudioEntryMutation>;
export type DeleteStudioEntryMutationResult = Apollo.MutationResult<GQLDeleteStudioEntryMutation>;
export type DeleteStudioEntryMutationOptions = Apollo.BaseMutationOptions<GQLDeleteStudioEntryMutation, GQLDeleteStudioEntryMutationVariables>;
export const StudioImagesDocument = gql`
    query studioImages($input: StudioImagesInput!) {
  studioImages(input: $input) {
    ...StudioImage
  }
}
    ${StudioImageFragmentDoc}`;

/**
 * __useStudioImagesQuery__
 *
 * To run a query within a React component, call `useStudioImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudioImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudioImagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudioImagesQuery(baseOptions: Apollo.QueryHookOptions<GQLStudioImagesQuery, GQLStudioImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLStudioImagesQuery, GQLStudioImagesQueryVariables>(StudioImagesDocument, options);
      }
export function useStudioImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLStudioImagesQuery, GQLStudioImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLStudioImagesQuery, GQLStudioImagesQueryVariables>(StudioImagesDocument, options);
        }
export type StudioImagesQueryHookResult = ReturnType<typeof useStudioImagesQuery>;
export type StudioImagesLazyQueryHookResult = ReturnType<typeof useStudioImagesLazyQuery>;
export type StudioImagesQueryResult = Apollo.QueryResult<GQLStudioImagesQuery, GQLStudioImagesQueryVariables>;
export const AddStudioImageDocument = gql`
    mutation addStudioImage($input: AddStudioImageInput!, $image: Upload!) {
  addStudioImage(input: $input, image: $image) {
    ...StudioImage
  }
}
    ${StudioImageFragmentDoc}`;
export type GQLAddStudioImageMutationFn = Apollo.MutationFunction<GQLAddStudioImageMutation, GQLAddStudioImageMutationVariables>;

/**
 * __useAddStudioImageMutation__
 *
 * To run a mutation, you first call `useAddStudioImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudioImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudioImageMutation, { data, loading, error }] = useAddStudioImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddStudioImageMutation(baseOptions?: Apollo.MutationHookOptions<GQLAddStudioImageMutation, GQLAddStudioImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLAddStudioImageMutation, GQLAddStudioImageMutationVariables>(AddStudioImageDocument, options);
      }
export type AddStudioImageMutationHookResult = ReturnType<typeof useAddStudioImageMutation>;
export type AddStudioImageMutationResult = Apollo.MutationResult<GQLAddStudioImageMutation>;
export type AddStudioImageMutationOptions = Apollo.BaseMutationOptions<GQLAddStudioImageMutation, GQLAddStudioImageMutationVariables>;
export const DeleteStudioImageDocument = gql`
    mutation deleteStudioImage($input: DeleteStudioImageInput!) {
  deleteStudioImage(input: $input)
}
    `;
export type GQLDeleteStudioImageMutationFn = Apollo.MutationFunction<GQLDeleteStudioImageMutation, GQLDeleteStudioImageMutationVariables>;

/**
 * __useDeleteStudioImageMutation__
 *
 * To run a mutation, you first call `useDeleteStudioImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudioImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudioImageMutation, { data, loading, error }] = useDeleteStudioImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStudioImageMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteStudioImageMutation, GQLDeleteStudioImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteStudioImageMutation, GQLDeleteStudioImageMutationVariables>(DeleteStudioImageDocument, options);
      }
export type DeleteStudioImageMutationHookResult = ReturnType<typeof useDeleteStudioImageMutation>;
export type DeleteStudioImageMutationResult = Apollo.MutationResult<GQLDeleteStudioImageMutation>;
export type DeleteStudioImageMutationOptions = Apollo.BaseMutationOptions<GQLDeleteStudioImageMutation, GQLDeleteStudioImageMutationVariables>;
export const StudioDocument = gql`
    query studio($input: StudioInput!) {
  studio(input: $input) {
    ...Studio
  }
}
    ${StudioFragmentDoc}`;

/**
 * __useStudioQuery__
 *
 * To run a query within a React component, call `useStudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudioQuery(baseOptions: Apollo.QueryHookOptions<GQLStudioQuery, GQLStudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLStudioQuery, GQLStudioQueryVariables>(StudioDocument, options);
      }
export function useStudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLStudioQuery, GQLStudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLStudioQuery, GQLStudioQueryVariables>(StudioDocument, options);
        }
export type StudioQueryHookResult = ReturnType<typeof useStudioQuery>;
export type StudioLazyQueryHookResult = ReturnType<typeof useStudioLazyQuery>;
export type StudioQueryResult = Apollo.QueryResult<GQLStudioQuery, GQLStudioQueryVariables>;
export const FullStudioDocument = gql`
    query fullStudio($input: StudioInput!) {
  studio(input: $input) {
    ...FullStudio
  }
}
    ${FullStudioFragmentDoc}`;

/**
 * __useFullStudioQuery__
 *
 * To run a query within a React component, call `useFullStudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullStudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullStudioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFullStudioQuery(baseOptions: Apollo.QueryHookOptions<GQLFullStudioQuery, GQLFullStudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLFullStudioQuery, GQLFullStudioQueryVariables>(FullStudioDocument, options);
      }
export function useFullStudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLFullStudioQuery, GQLFullStudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLFullStudioQuery, GQLFullStudioQueryVariables>(FullStudioDocument, options);
        }
export type FullStudioQueryHookResult = ReturnType<typeof useFullStudioQuery>;
export type FullStudioLazyQueryHookResult = ReturnType<typeof useFullStudioLazyQuery>;
export type FullStudioQueryResult = Apollo.QueryResult<GQLFullStudioQuery, GQLFullStudioQueryVariables>;
export const StudiosDocument = gql`
    query studios($input: StudiosInput!) {
  studios(input: $input) {
    count
    items {
      ...Studio
    }
  }
}
    ${StudioFragmentDoc}`;

/**
 * __useStudiosQuery__
 *
 * To run a query within a React component, call `useStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudiosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudiosQuery(baseOptions: Apollo.QueryHookOptions<GQLStudiosQuery, GQLStudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLStudiosQuery, GQLStudiosQueryVariables>(StudiosDocument, options);
      }
export function useStudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLStudiosQuery, GQLStudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLStudiosQuery, GQLStudiosQueryVariables>(StudiosDocument, options);
        }
export type StudiosQueryHookResult = ReturnType<typeof useStudiosQuery>;
export type StudiosLazyQueryHookResult = ReturnType<typeof useStudiosLazyQuery>;
export type StudiosQueryResult = Apollo.QueryResult<GQLStudiosQuery, GQLStudiosQueryVariables>;
export const FullStudiosDocument = gql`
    query fullStudios($input: StudiosInput!) {
  studios(input: $input) {
    count
    items {
      ...FullStudio
    }
  }
}
    ${FullStudioFragmentDoc}`;

/**
 * __useFullStudiosQuery__
 *
 * To run a query within a React component, call `useFullStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullStudiosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFullStudiosQuery(baseOptions: Apollo.QueryHookOptions<GQLFullStudiosQuery, GQLFullStudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLFullStudiosQuery, GQLFullStudiosQueryVariables>(FullStudiosDocument, options);
      }
export function useFullStudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLFullStudiosQuery, GQLFullStudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLFullStudiosQuery, GQLFullStudiosQueryVariables>(FullStudiosDocument, options);
        }
export type FullStudiosQueryHookResult = ReturnType<typeof useFullStudiosQuery>;
export type FullStudiosLazyQueryHookResult = ReturnType<typeof useFullStudiosLazyQuery>;
export type FullStudiosQueryResult = Apollo.QueryResult<GQLFullStudiosQuery, GQLFullStudiosQueryVariables>;
export const CreateStudioDocument = gql`
    mutation createStudio($input: CreateStudioInput!) {
  createStudio(input: $input) {
    ...FullStudio
  }
}
    ${FullStudioFragmentDoc}`;
export type GQLCreateStudioMutationFn = Apollo.MutationFunction<GQLCreateStudioMutation, GQLCreateStudioMutationVariables>;

/**
 * __useCreateStudioMutation__
 *
 * To run a mutation, you first call `useCreateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudioMutation, { data, loading, error }] = useCreateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudioMutation(baseOptions?: Apollo.MutationHookOptions<GQLCreateStudioMutation, GQLCreateStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLCreateStudioMutation, GQLCreateStudioMutationVariables>(CreateStudioDocument, options);
      }
export type CreateStudioMutationHookResult = ReturnType<typeof useCreateStudioMutation>;
export type CreateStudioMutationResult = Apollo.MutationResult<GQLCreateStudioMutation>;
export type CreateStudioMutationOptions = Apollo.BaseMutationOptions<GQLCreateStudioMutation, GQLCreateStudioMutationVariables>;
export const UpdateStudioDocument = gql`
    mutation updateStudio($input: UpdateStudioInput!) {
  updateStudio(input: $input) {
    ...FullStudio
  }
}
    ${FullStudioFragmentDoc}`;
export type GQLUpdateStudioMutationFn = Apollo.MutationFunction<GQLUpdateStudioMutation, GQLUpdateStudioMutationVariables>;

/**
 * __useUpdateStudioMutation__
 *
 * To run a mutation, you first call `useUpdateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudioMutation, { data, loading, error }] = useUpdateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudioMutation(baseOptions?: Apollo.MutationHookOptions<GQLUpdateStudioMutation, GQLUpdateStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLUpdateStudioMutation, GQLUpdateStudioMutationVariables>(UpdateStudioDocument, options);
      }
export type UpdateStudioMutationHookResult = ReturnType<typeof useUpdateStudioMutation>;
export type UpdateStudioMutationResult = Apollo.MutationResult<GQLUpdateStudioMutation>;
export type UpdateStudioMutationOptions = Apollo.BaseMutationOptions<GQLUpdateStudioMutation, GQLUpdateStudioMutationVariables>;
export const DeleteStudioDocument = gql`
    mutation deleteStudio($input: DeleteStudioInput!) {
  deleteStudio(input: $input)
}
    `;
export type GQLDeleteStudioMutationFn = Apollo.MutationFunction<GQLDeleteStudioMutation, GQLDeleteStudioMutationVariables>;

/**
 * __useDeleteStudioMutation__
 *
 * To run a mutation, you first call `useDeleteStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudioMutation, { data, loading, error }] = useDeleteStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStudioMutation(baseOptions?: Apollo.MutationHookOptions<GQLDeleteStudioMutation, GQLDeleteStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLDeleteStudioMutation, GQLDeleteStudioMutationVariables>(DeleteStudioDocument, options);
      }
export type DeleteStudioMutationHookResult = ReturnType<typeof useDeleteStudioMutation>;
export type DeleteStudioMutationResult = Apollo.MutationResult<GQLDeleteStudioMutation>;
export type DeleteStudioMutationOptions = Apollo.BaseMutationOptions<GQLDeleteStudioMutation, GQLDeleteStudioMutationVariables>;
export const UserDocument = gql`
    query user($userId: String, $userName: String) {
  user(userId: $userId, userName: $userName) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<GQLUserQuery, GQLUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLUserQuery, GQLUserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLUserQuery, GQLUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLUserQuery, GQLUserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<GQLUserQuery, GQLUserQueryVariables>;
export const UserWithJobsDocument = gql`
    query userWithJobs($userName: String!) {
  user(userName: $userName) {
    ...User
    jobs {
      ...Job
      user {
        ...User
      }
      photographer {
        ...Photographer
      }
    }
  }
}
    ${UserFragmentDoc}
${JobFragmentDoc}
${PhotographerFragmentDoc}`;

/**
 * __useUserWithJobsQuery__
 *
 * To run a query within a React component, call `useUserWithJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithJobsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useUserWithJobsQuery(baseOptions: Apollo.QueryHookOptions<GQLUserWithJobsQuery, GQLUserWithJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLUserWithJobsQuery, GQLUserWithJobsQueryVariables>(UserWithJobsDocument, options);
      }
export function useUserWithJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLUserWithJobsQuery, GQLUserWithJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLUserWithJobsQuery, GQLUserWithJobsQueryVariables>(UserWithJobsDocument, options);
        }
export type UserWithJobsQueryHookResult = ReturnType<typeof useUserWithJobsQuery>;
export type UserWithJobsLazyQueryHookResult = ReturnType<typeof useUserWithJobsLazyQuery>;
export type UserWithJobsQueryResult = Apollo.QueryResult<GQLUserWithJobsQuery, GQLUserWithJobsQueryVariables>;
export const FullUserDocument = gql`
    query fullUser($userId: String, $userName: String) {
  user(userId: $userId, userName: $userName) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useFullUserQuery__
 *
 * To run a query within a React component, call `useFullUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useFullUserQuery(baseOptions?: Apollo.QueryHookOptions<GQLFullUserQuery, GQLFullUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLFullUserQuery, GQLFullUserQueryVariables>(FullUserDocument, options);
      }
export function useFullUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLFullUserQuery, GQLFullUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLFullUserQuery, GQLFullUserQueryVariables>(FullUserDocument, options);
        }
export type FullUserQueryHookResult = ReturnType<typeof useFullUserQuery>;
export type FullUserLazyQueryHookResult = ReturnType<typeof useFullUserLazyQuery>;
export type FullUserQueryResult = Apollo.QueryResult<GQLFullUserQuery, GQLFullUserQueryVariables>;
export const FullUsersDocument = gql`
    query fullUsers($limit: Int!, $offset: Int!) {
  users(limit: $limit, offset: $offset) {
    count
    items {
      ...FullUser
    }
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useFullUsersQuery__
 *
 * To run a query within a React component, call `useFullUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFullUsersQuery(baseOptions: Apollo.QueryHookOptions<GQLFullUsersQuery, GQLFullUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLFullUsersQuery, GQLFullUsersQueryVariables>(FullUsersDocument, options);
      }
export function useFullUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLFullUsersQuery, GQLFullUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLFullUsersQuery, GQLFullUsersQueryVariables>(FullUsersDocument, options);
        }
export type FullUsersQueryHookResult = ReturnType<typeof useFullUsersQuery>;
export type FullUsersLazyQueryHookResult = ReturnType<typeof useFullUsersLazyQuery>;
export type FullUsersQueryResult = Apollo.QueryResult<GQLFullUsersQuery, GQLFullUsersQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($user: RegisterUserInput!) {
  registerUser(user: $user) {
    id
    email
    name
    screenName
    sex
    birthdate
    phoneNumber
    zipcode
    address
    description
    createdAt
    updatedAt
  }
}
    `;
export type GQLRegisterUserMutationFn = Apollo.MutationFunction<GQLRegisterUserMutation, GQLRegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<GQLRegisterUserMutation, GQLRegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLRegisterUserMutation, GQLRegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<GQLRegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<GQLRegisterUserMutation, GQLRegisterUserMutationVariables>;
export const ProfileDocument = gql`
    query profile {
  profile {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<GQLProfileQuery, GQLProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GQLProfileQuery, GQLProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GQLProfileQuery, GQLProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GQLProfileQuery, GQLProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<GQLProfileQuery, GQLProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($profile: ProfileInput!) {
  updateProfile(profile: $profile) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export type GQLUpdateProfileMutationFn = Apollo.MutationFunction<GQLUpdateProfileMutation, GQLUpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<GQLUpdateProfileMutation, GQLUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLUpdateProfileMutation, GQLUpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<GQLUpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<GQLUpdateProfileMutation, GQLUpdateProfileMutationVariables>;
export const UpdateProfileImageDocument = gql`
    mutation updateProfileImage($image: Upload!) {
  updateProfileImage(image: $image) {
    url
    width
    height
    size
    createdAt
  }
}
    `;
export type GQLUpdateProfileImageMutationFn = Apollo.MutationFunction<GQLUpdateProfileImageMutation, GQLUpdateProfileImageMutationVariables>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<GQLUpdateProfileImageMutation, GQLUpdateProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GQLUpdateProfileImageMutation, GQLUpdateProfileImageMutationVariables>(UpdateProfileImageDocument, options);
      }
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<GQLUpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<GQLUpdateProfileImageMutation, GQLUpdateProfileImageMutationVariables>;