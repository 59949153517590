import React from 'react';
import { GQLJob, GQLJobStatus, useJobPaymentIntentQuery } from '../../generated/graphql';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PhotographerProps } from './photographer';
import { PartialRequired } from '../../utils/type-helper';
import { SinglePageOuter } from '../atoms/single-page-outer';
import { SinglePageBreadcrumbsContainer } from '../atoms/single-page-breadcrumbs-container';
import { Breadcrumbs } from './breadcrumbs';
import { SinglePageInner } from '../atoms/single-page-inner';
import { JobPageDetailHead } from './job-page-detail-head';
import { SinglePageDetail } from './single-page-detail';
import { SinglePageContentContainer } from '../atoms/single-page-content-container';
import { JobPageMap } from './job-page-map';
import { formatDate } from '../../utils/format-date';
import { ApplyForJobButton } from './apply-for-job-button';
import { JobComments } from './job-comments';
import { SelectContractor } from './select-contractor';
import { JobMessages } from './job-messages';
import { DownArrow, Mail, Person, UpArrow } from '../../generated/svg';
import { compareJobStatus } from '../../utils/compare-job-status';
import { UploadDeliverable } from './upload-deliverable';
import { DeliverableList } from './deliverable-list';
import { usePaymentModal } from '../../hooks/use-payment-modal';
import { useApolloClient } from '@apollo/client';

const Outer = styled.div`
  --accent-color: #669933;
`

const Inner = styled.div`
  display: flex;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  background-color: white;
  @media (max-width: 40rem){
    flex: 0 0 auto;
    order: 2;
  }
`
const RightContainer = styled.div`
  flex: 1 1 0;
  background-color: #4d4d4d;
  padding: 1rem;
  align-self: stretch;
  @media (max-width: 40rem){
    flex: 0 0 auto;
    order: 1;
  }
`

const MapContainer = styled.div`
  height: 30rem;
`

const JobOuter = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #000;
`

const ApplicationButton = styled.div`
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  width: 8rem;
  text-align: center;
  border-radius: 0.25rem;
`

const ContentContainer = styled.div`
`
const RightContainerDetail = styled.div`
  padding: 1rem 0.5rem;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
`

const SelectContractorContainer = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  --accent-color: #005938;
`

const ContentOuter = styled.div`
  padding: 3rem 1rem;
  border-top: 1px solid #ccc;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`

const TitleIconContainer = styled.div`
  width: 2.5rem;
  color: var(--accent-color);
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--accent-color);
  margin-left: 0.5rem;
`

const Text = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
`

const JobMessageContainer = styled.div`

`

const DeliverablePreviewContainer = styled.div`

`

const PaymentButton = styled.div`
  background-color: var(--accent-color);
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  margin: 3rem auto 0;
  color: white;
  max-width: 16rem;
  border-radius: 0.25rem;
  cursor: pointer;
`

const CompleteMessage = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
`

type JobProps = {
  job: PartialRequired<GQLJob, 'name' | 'fee' | 'deadline' | 'address' | 'lat' | 'lng' | 'status' | 'id' | 'applicationCount' | 'createdAt' | 'status' | 'assigned' | 'own'> & {
    photographer?: PhotographerProps['photographer'] | null,
  }
}

export const Job: FunctionComponent<JobProps> = ({job}) => {
  const client = useApolloClient()
  const {open: openPaymentModal} = usePaymentModal()
  const jobPaymentIntentQuery = useJobPaymentIntentQuery({
    variables: {
      jobId: job.id
    }
  })
  return <Outer>
    <SinglePageOuter>
      <SinglePageBreadcrumbsContainer>
        <Breadcrumbs items={[
          {
            label: 'Top',
            url: '/'
          }, {
            label: '依頼',
            url: '/jobs',
          }, {
            label: job.area?.prefecture || 'エリア無し'
          }
        ]} />
      </SinglePageBreadcrumbsContainer>
      {
        job.status === GQLJobStatus.Completed &&
        <CompleteMessage>取引が完了しました</CompleteMessage>
      }
      <SinglePageInner>
        <Inner>
          <LeftContainer>
            <JobPageDetailHead job={job} />
            <SinglePageContentContainer>
              <SinglePageDetail title="ORDER">
                {job.description}
              </SinglePageDetail>
              <SinglePageDetail title="COMMENT/FAQ">
                <JobComments jobId={job.id} />
              </SinglePageDetail>
            </SinglePageContentContainer>
          </LeftContainer>
          <RightContainer>
            <MapContainer>
              <JobPageMap lat={job.lat} lng={job.lng} />
            </MapContainer>
            <RightContainerDetail>
              募集開始：{formatDate(job.createdAt)}
            </RightContainerDetail>
            <ApplyForJobButton job={job}></ApplyForJobButton>
          </RightContainer>
        </Inner>
      </SinglePageInner>
    </SinglePageOuter>
    <SinglePageOuter>
      <SinglePageInner>
        {
          job.own && job.status === GQLJobStatus.Open &&
          <ContentOuter>
            <Header>
              <TitleIconContainer>
                <Person />
              </TitleIconContainer>      
              <Title>
                依頼先を選択する
              </Title>
            </Header>
            <SelectContractorContainer>
              <SelectContractor jobId={job.id} />
            </SelectContractorContainer>
          </ContentOuter>
        }
        {
          (job.own || job.assigned) && compareJobStatus(job.status, GQLJobStatus.Closed) >= 0 &&
          <ContentOuter>
            <Header>
              <TitleIconContainer>
                <Mail />
              </TitleIconContainer>
              <Title>
                メッセージ
              </Title>
            </Header>
            <JobMessageContainer>
              <JobMessages jobId={job.id} />
            </JobMessageContainer>
          </ContentOuter>
        }
        {
          job.own && compareJobStatus(job.status, GQLJobStatus.Closed) <= 0 &&
          <Text>カメラマンが納品データをアップロード後支払いの手続きが可能になります。</Text>
        }
        {
          job.own && compareJobStatus(job.status, GQLJobStatus.Preview) >= 0 &&
          <ContentOuter>
            <Header>
              <TitleIconContainer>
                <DownArrow />
              </TitleIconContainer>
              <Title>
                写真をダウンロード
              </Title>
            </Header>
            <DeliverablePreviewContainer>
              <DeliverableList jobId={job.id} />
            </DeliverablePreviewContainer>
          </ContentOuter>
        }
        {
          job.assigned && compareJobStatus(job.status, GQLJobStatus.Closed) >= 0 &&
          <ContentOuter>
            <Header>
              <TitleIconContainer>
                <UpArrow />
              </TitleIconContainer>
              <Title>
                写真をアップロード
              </Title>
            </Header>
            <DeliverablePreviewContainer>
              <DeliverableList jobId={job.id} />
            </DeliverablePreviewContainer>
            <DeliverablePreviewContainer>
              <UploadDeliverable jobId={job.id} />
            </DeliverablePreviewContainer>
          </ContentOuter>
        }
        {
          job.own && job.status === GQLJobStatus.Deliverd &&
          <PaymentButton onClick={async () => {
            if(jobPaymentIntentQuery.data){
              await openPaymentModal({
                amount: jobPaymentIntentQuery.data.jobPaymentIntent.amount,
                paymentIntentClientSecret: jobPaymentIntentQuery.data.jobPaymentIntent.clientSecret
              })
              await client.reFetchObservableQueries()
            }
          }}>支払う</PaymentButton>
        }
        {
          job.status === GQLJobStatus.Completed &&
          <CompleteMessage>取引が完了しました</CompleteMessage>
        }
      </SinglePageInner>
    </SinglePageOuter>
  </Outer>
}