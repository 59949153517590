import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface CheckboxListProps {
  entries: {label: string, value: string}[],
  checked: string[],
  onChange: (checked: string[]) => void
}

const CheckboxListOuter = styled.div`
  margin: 1rem 0;
`
const CheckboxLabel = styled.label`
  display: block;
`

export const CheckboxList: FunctionComponent<CheckboxListProps> = ({entries, checked, onChange}) => {
  return <CheckboxListOuter>
    {entries.map(({label, value}, i) => {
      return <CheckboxLabel key={i}>
        <input type="checkbox"
          onChange={e => {
            onChange(entries.filter(({value}, j) => {
              if(i === j){
                return e.currentTarget.checked
              } else {
                return checked.includes(value)
              }
            }).map(({value}) => value))
          }}
          checked={checked.includes(value)}
        />
        {label}
      </CheckboxLabel>
    })}
  </CheckboxListOuter>
}