import React, { useState } from 'react';
import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { GQLRegisterUserInput, GQLUser, useRegisterUserMutation } from '../../generated/graphql';
import { createForm } from '../organisms/forms';
import { useHistory } from 'react-router-dom';
import { InputContainer } from '../molecules/input-container';
import { FormButton } from '../organisms/form-button';
import { isNotIncludeUndefined } from '../../utils/type-check';
import { PartialRequired } from '../../utils/type-helper';
import { useErrorModal } from '../../hooks/use-error-modal';
import { ResolvedType } from '../../utils/type-utils';

const Outer = styled.div`
  background-color: #eeeeee;
  padding: 4rem 2rem;
  --accent-color: #70a52e;
`

const Inner = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`

const Title = styled.div`
  color: var(--accent-color);
  font-weight: 700;
  margin-bottom: 3rem;
  font-size: 1.25rem;
  text-align: center;
`

const P = styled.p`
  text-align: center;
`

const ButtonOuter = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

const {
  useForm,
  Form,
  Text,
  Textarea,
  Checkbox,
  NumberText,
  DateInput,
  Password,
  LocationInput: PlaceInput,
  DropdownDateInput
} = createForm<Partial<GQLRegisterUserInput>>()

export const SignUpPage: FunctionComponent = () => {
  const [values, formProps] = useForm({
    address: undefined,
    birthdate: undefined,
    email: undefined,
    equipmentIds: [],
    name: undefined,
    password: undefined,
    phoneNumber: undefined,
    screenName: undefined,
    zipcode: undefined
  })

  const history = useHistory()
  const [registerUserMutation] = useRegisterUserMutation()
  const [completed, setCompleted] = useState(false)
  const [registeredUser, setRegisteredUser] = useState<PartialRequired<GQLUser, 'email'>>()
  const {open: openErrorModal} = useErrorModal()

  return <Outer>
    <Inner>
      { completed ? <>
        <Title>送信が完了しました</Title>
        <P>認証メールを{registeredUser?.email}に送信しました。</P>
      </> :<>
        <Title>会員登録</Title>
        <Form {...formProps}>
          <InputContainer label="ユーザーID(公開)" required><Text name="name" placeholder="third_wheel"></Text></InputContainer>
          <InputContainer label="ユーザー名(公開)" required><Text name="screenName" placeholder="サードホイール"></Text></InputContainer>
          <InputContainer label="生年月日" required><DropdownDateInput name="birthdate" /></InputContainer>
          <InputContainer label="メールアドレス" required><Text name="email" placeholder="info@example.com"/></InputContainer>
          <InputContainer label="パスワード" required><Password name="password"/></InputContainer>
          <InputContainer label="電話番号" required><Text name="phoneNumber" placeholder="090xxxxxxxx"/></InputContainer>
          <InputContainer label="郵便番号" required><Text name="zipcode" placeholder="1234567"/></InputContainer>
          <InputContainer label="住所" required> <Text name="address" placeholder="東京都千代田区1-1"/></InputContainer>
        </Form>
        <ButtonOuter>
          <FormButton disabled={!isNotIncludeUndefined(values)} onClick={async () => {
            if(isNotIncludeUndefined(values)){
              let res: ResolvedType<ReturnType<typeof registerUserMutation>>
              try {
                res = await registerUserMutation({
                  variables: {
                    user: values
                  }
                })
              } catch(err) {
                await openErrorModal(err)
                return
              }
              res.data?.registerUser
              setCompleted(true)
              setRegisteredUser(res.data?.registerUser)
            }
          }}>登録内容を送信する</FormButton>
        </ButtonOuter>
      </>}
    </Inner>
  </Outer>
}