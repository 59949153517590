import { ApolloError } from '@apollo/client'
import React from 'react'
import { ErrorModal } from '../components/organisms/error-modal'
import { isNullish } from '../utils/type-check'
import { useModal } from './use-modal'

export const useErrorModal = () => {
  const {open} = useModal()
  return {
    open: async (err?: any) => {
      const messages = getMessages(err)
      if(messages.length > 0){
        return open(({resolve}) => <ErrorModal messages={messages} onClose={() => resolve(undefined)} />)
      } else {
        return open(({resolve}) => <ErrorModal messages={['エラーが発生しました']} onClose={() => resolve(undefined)} />)
      }
    }
  }
}
const getMessages = (err: any): string[] => {
  if(err instanceof ApolloError){
    const messages = err.graphQLErrors.map(err => {
      if(err.extensions?.messages){
        const messages = err.extensions?.messages
        if(Array.isArray(messages) && messages.every(message => typeof message === 'string')){
          return messages
        }
      } else {
        return [err.message]
      }
    }).flat(1).filter(Boolean) as string[]
    return messages
  }
  if(typeof err.message === 'string'){
    console.dir(err.message)
    return [err.message]
  }

  return []
}