import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAddPaymentMethodMutation, usePaymentMethodsQuery } from '../../generated/graphql';

const Outer = styled.div``

const stripePromise = loadStripe('pk_test_dBqa47JbW9sSiQIWHOihZOOt003Tg2ZNRh')

export const AddPaymentMethodPage: FunctionComponent = () => {

  return <Elements stripe={stripePromise} options={{locale: 'ja'}}>
    <AddPaymentMethod />
  </Elements>
}

const AddPaymentMethod: FunctionComponent = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [addPaymentMethod] = useAddPaymentMethodMutation()
  const paymentMethodsQuery = usePaymentMethodsQuery()
  
  const submit = async () => {
    if(!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)

    if(!cardElement) return

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })
    if(error) console.log(error)
    if(!paymentMethod) return

    await addPaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id
      }
    })
    await paymentMethodsQuery.refetch()

  }

  return <Outer>
    {
      paymentMethodsQuery.data?.paymentMethods.map(paymentMethod => <Outer key={paymentMethod.id}>{paymentMethod.last4Digits}</Outer>)
    }
    <CardElement options={{hidePostalCode: true}} />
    <button onClick={submit} disabled={!stripe}>Add</button>
  </Outer>
}