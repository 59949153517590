import React, { useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import SvgMenuClose from '../../generated/svg/MenuClose'
import SvgMenuHamburger from '../../generated/svg/MenuHamburger'
import { Navigator, NavigatorItem } from '../atoms/navigator'

const Outer = styled.div`
`

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-self: stretch;
`

const ButtonOuter = styled.div`
  background-color: #efefef;
  padding: 0.5rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const HamburgerOuter = styled.div`
  color: white;
  height: 2.5rem;
  svg {
    display: block;
    height: 100%;
  }
`

const NavigatorOuter = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--base-color);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CloseButtonOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.75rem;
  height: 1.75rem;
  padding: 1rem;
  cursor: pointer;
`


export const MobileMenu: FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setOpen(false)
  }, [location.key])
  return <Outer>
    <ButtonContainer>
      <ButtonOuter onClick={() => setOpen(prev => !prev)}>
        <HamburgerOuter>
          <SvgMenuHamburger />
        </HamburgerOuter>
      </ButtonOuter>
    </ButtonContainer>
    {
      open && <NavigatorOuter>
        <CloseButtonOuter onClick={() => setOpen(prev => !prev)}>
          <SvgMenuClose />
        </CloseButtonOuter>
        <Navigator>
          <NavigatorItem to="/">TOP</NavigatorItem>
          <NavigatorItem to="/usage">このサイトの使い方</NavigatorItem>
          <NavigatorItem to="/help">ヘルプ</NavigatorItem>
          <NavigatorItem to="/faq">FAQ</NavigatorItem>
          <NavigatorItem to="/terms-of-service">利用規約</NavigatorItem>
          <NavigatorItem to="/contact">お問い合わせ</NavigatorItem>
        </Navigator>
      </NavigatorOuter>
    }

  </Outer>
}