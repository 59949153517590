import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useNearJobsQuery } from '../../generated/graphql';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { useUserPosition } from '../../hooks/use-user-position';
import { useHistory } from 'react-router-dom';

export type LatLng = {
  lat: number,
  lng: number
}
const MapOuter = styled.div`
  width: 100%;
  height: 30rem;
`

const libraries: Libraries = ['places']
export const NearJobMap: FunctionComponent = () => {
  const {position, loaded, error} = useUserPosition() // Tokoyo station coordinates as default value
  const history = useHistory()
  const nearJobsQuery = useNearJobsQuery({
    variables: {
      lat: position?.lat ?? 0,
      lng: position?.lng ?? 0
    }
  })
  return <MapOuter>
    <GoogleMap
      mapContainerStyle={{width: '100%', height: '100%'}}
      center={position ?? {lat: 35.6812362, lng: 139.7671248}}
      zoom={13}
    >
      {nearJobsQuery.data?.nearJobs.map(job => {
        return <Marker
          key={job.id}
          onClick={() => {
            history.push(`/jobs/${job.id}`)
          }}
          position={{
            lat: job.lat,
            lng: job.lng
          }}
        />
      })}
    </GoogleMap>
  </MapOuter>
}