import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, PaymentIntent } from '@stripe/stripe-js'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Loading } from '../../admin/components/loading'
import { useErrorModal } from '../../hooks/use-error-modal'
import { formatPrice } from '../../utils/format-price'
import { Modal } from './modal'


const Outer = styled.div`
  min-width: 20rem;
`

const Amount = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`

const PaymentButton = styled.div`
  background-color: #70a52e;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  margin: 3rem auto 0;
  color: white;
  max-width: 16rem;
  border-radius: 0.25rem;
  cursor: pointer;
`

type PaymentModalProps = {
  paymentIntentClientSecret: string,
  amount: number,
  onClose: () => void,
  onComplete: (paymentIntent: PaymentIntent) => void
}

export const PaymentModal: FunctionComponent<PaymentModalProps> = ({paymentIntentClientSecret, amount, onClose, onComplete}) => {
  const stripe = useStripe()
  const elements = useElements()
  const {open: openErrorModal} = useErrorModal()
  const [sending, setSending] = useState(false)
  if(!stripe || !elements) return null
  return <Modal onClose={onClose}>
    <Outer>
      {
        sending ?
        <Loading /> :
        <>
          <Amount>{formatPrice(amount)}</Amount>
          <CardElement />
          <PaymentButton onClick={async () => {
            const cardElement = elements?.getElement('card')
            if(!cardElement) return
            setSending(true)
            try {
              const res = await stripe.confirmCardPayment(paymentIntentClientSecret, {
                payment_method: {
                  card: cardElement
                }
              })
              if(res.error){
                openErrorModal(res.error)
                return
              }
              if(!res.paymentIntent){
                openErrorModal()
                return
              }
              onComplete(res.paymentIntent)
            } catch(err) {
              openErrorModal(err)
            } finally {
              setSending(false)
            }
    
          }}>
            支払う
          </PaymentButton>
        </>
      }
    </Outer>
  </Modal>
}