import React, { FunctionComponent } from 'react';
import { GQLJobInput, useCreateJobMutation } from '../../generated/graphql';
import SvgCameraIcon from '../../generated/svg/CameraIcon';
import SvgPerson from '../../generated/svg/Person';
import { AccountPageOuter, AccountPageTabContainer, AccountPageHead, AccountPageHeadIconContainer, AccountPageHeadLabelContainer, AccountPageHeadLabel, AccountPageHeadLabelEnglish } from '../organisms/account-page-parts';
import { FormContainer } from '../organisms/form-container';
import { createForm } from '../organisms/forms';
import { FormButton } from '../organisms/form-button';
import { AccountPageTabs } from './account-page-tabs';
import { InputContainer } from './input-container';
import { isNotIncludeUndefined, isNotNullish } from '../../utils/type-check';
import { useHistory, useLocation } from 'react-router-dom';
import { useErrorModal } from '../../hooks/use-error-modal';

const {
  useForm,
  Form,
  Text,
  Textarea,
  Checkbox,
  NumberText,
  DateInput,
  LocationInput
} = createForm<Partial<GQLJobInput>>()

export const AddJobTab: FunctionComponent = () => {
  const [values, formProps] = useForm({
    address: '',
    areaCode: undefined,
    categoryNames: [],
    deadline: undefined,
    description: '',
    fee: undefined,
    lat: undefined,
    lng: undefined,
    name: ''
  })
  const history = useHistory()
  const {open: openErrorModal} = useErrorModal()
  const [createJobMutation] = useCreateJobMutation()
  return <AccountPageOuter>
    <AccountPageTabContainer>
      <AccountPageTabs active="addJob" />
    </AccountPageTabContainer>
    <AccountPageHead>
      <AccountPageHeadIconContainer>
        <SvgPerson /> 
      </AccountPageHeadIconContainer>
      <AccountPageHeadLabelContainer>
        <AccountPageHeadLabel>依頼を登録する</AccountPageHeadLabel>
        <AccountPageHeadLabelEnglish>ADD JOB</AccountPageHeadLabelEnglish>
      </AccountPageHeadLabelContainer>
    </AccountPageHead>
    <FormContainer>
      <Form {...formProps}>
        <InputContainer label="案件名"><Text name="name" /></InputContainer>
        <InputContainer label="撮影場所">
        <LocationInput addressName="address" latName="lat" lngName="lng" areaCodeName="areaCode" style={{width: '100%', height: '20rem'}} />
        </InputContainer>
        <InputContainer label="撮影希望日時"><DateInput name="deadline"/></InputContainer>
        <InputContainer label="報酬"><NumberText name="fee" /></InputContainer>
        <InputContainer label="依頼概要"><Textarea name="description" /></InputContainer>
      </Form>
      <FormButton disabled={!isNotIncludeUndefined(values)} onClick={async () => {
        if(isNotIncludeUndefined(values)){
          try {
            const res = await createJobMutation({
              variables: {
                job: values
              }
            })
            const id = res.data?.createJob.id
            if(isNotNullish(id)){
              history.replace(`/jobs/${id}`)
            }
          } catch(err) {
            openErrorModal(err)
          }
        }
      }}>依頼を登録する</FormButton>
    </FormContainer>
  </AccountPageOuter>
}